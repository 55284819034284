import React, { useEffect } from "react";
import { Plus } from "lucide-react";
import { useMerchant, userDataStore } from "../../store";
import Badge from "../shared/atoms/Badge";
import Avatar from "react-avatar";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import Loader from "../shared/atoms/Loader";
import { removeCookie } from "../../utils";

const SwitchAccountDialog = () => {
  const { setIsSwitchAccountDialigOpen } = useMerchant();
  const { authInfo, loginAuth, isSwitchAccountLoading, isSwitchAccountSuccess, setIsSwitchAccountSuccess, switchAccountWorker } =
    userDataStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isSwitchAccountSuccess) {
      navigate("/v1/home");
      removeCookie("impersonatedMerchantAccountId");
      removeCookie("impersonatedMerchantUserId");
      loginAuth();
      setIsSwitchAccountSuccess(false);
      setIsSwitchAccountDialigOpen(false);
    }
  }, [isSwitchAccountSuccess]);

  return (
    <div className="relative px-8 py-6 w-auto min-w-[336px] max-h-[80vh] overflow-y-auto">
      <div className="text-black text-lg font-semibold mb-3">Choose your account</div>
      <div className="grid">
        {(authInfo.merchantAccounts ?? []).map((account) => (
          <div
            key={account.id}
            className="grid grid-cols-[auto_1fr_auto] items-center cursor-pointer w-full border-b py-3 hover:bg-gray-50"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (account.id !== authInfo.merchantAccountId) {
                switchAccountWorker(authInfo.id, account.id);
              }
            }}
          >
            <div className="flex gap-3 items-center cursor-pointer">
              <div className="rounded-full overflow-hidden" style={{ height: "32px", width: "32px" }}>
                {account?.branding_settings?.icon_image_url ? (
                  <img
                    style={{ objectFit: "cover", height: "100%", width: "100%" }}
                    src={account?.branding_settings?.icon_image_url + "?" + Date.now() ?? ""}
                    alt=""
                  />
                ) : (
                  <Avatar
                    name={account?.email ? account.email.split("").join(" ") : account?.name}
                    size={32}
                    maxInitials={2}
                    className={styles.avatarFallback}
                  />
                )}
              </div>
              <div className="text-black font-normal text-base">{account?.name ? account.name : account?.email}</div>
            </div>

            <div className="justify-self-end flex-shrink-0 text-xs w-[73px] ml-4">
              {account.id === authInfo.merchantAccountId && <Badge type={"primary"} label={"Selected"} />}
            </div>
          </div>
        ))}
      </div>
      <div
        onClick={() => {
          window.location.href = `/businessForm`;
        }}
        className="flex items-center gap-3 min-w-0 cursor-pointer pt-4"
      >
        <div className="h-8 w-8 rounded-full overflow-hidden flex-shrink-0 bg-blue-100 flex items-center justify-center">
          <Plus size={28} strokeWidth="1" />
        </div>
        <span className="text-black font-normal text-base truncate w-full">Create new account</span>
      </div>

      {/* Loader Overlay when isSwitchAccountLoading is true */}
      {isSwitchAccountLoading && (
        <div className="absolute inset-0 bg-white/70 flex items-center justify-center z-10">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default SwitchAccountDialog;
