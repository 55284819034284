import * as React from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useTheme } from "../../store";

function AdminIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        className={styles}
        d="M18.073 11.558a1.243 1.243 0 01-.442-1.428 7.55 7.55 0 00.414-3.244 7.365 7.365 0 00-2.203-4.644c-.422-.413-1.101-.273-1.404.234l-.17.284c-.263.442-.14 1.005.22 1.374a4.894 4.894 0 011.397 2.993c.12 1.334-.303 2.647-1.163 3.603l-.266.295a1 1 0 00.433 1.62l.295.096c3.345 1.09 4.282 4.11 4.544 5.69.09.546.55.993 1.102.993H21c.552 0 1.003-.448.937-.996-.248-2.07-1.271-5.041-3.864-6.87z"
      ></path>
      <path
        className={styles}
        d="M10.095 12.056c2.381 0 4.318-2.125 4.318-4.737 0-2.613-1.937-4.737-4.318-4.737-2.38 0-4.317 2.124-4.317 4.737 0 2.612 1.936 4.737 4.317 4.737zm0-7.106c1.19 0 2.159 1.062 2.159 2.369 0 1.306-.968 2.368-2.159 2.368-1.19 0-2.158-1.062-2.158-2.368 0-1.307.968-2.369 2.158-2.369zm1.62 8.29H8.475C4.905 13.24 2 16.426 2 20.345v.184a1 1 0 001 1h.159a1 1 0 001-1v-.184c0-2.612 1.936-4.737 4.317-4.737h3.238c2.381 0 4.318 2.125 4.318 4.737v.184a1 1 0 001 1h.159a1 1 0 001-1v-.184c0-3.919-2.905-7.105-6.477-7.105z"
      ></path>
    </svg>
  );
}

function DevelopersIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        d="M13.452 22h-2.904c-.71 0-1.313-.462-1.468-1.124l-.369-1.561c-.6-.242-1.17-.56-1.706-.945l-1.59.48c-.683.2-1.426-.076-1.768-.658l-1.448-2.39a1.41 1.41 0 01.281-1.779l1.233-1.081a8.02 8.02 0 010-1.884L2.486 9.982a1.407 1.407 0 01-.291-1.776L3.65 5.802c.34-.575 1.08-.854 1.762-.651l1.592.479a8.144 8.144 0 011.706-.945l.369-1.562C9.235 2.463 9.839 2 10.548 2h2.904c.709 0 1.312.462 1.468 1.124l.368 1.561c.6.243 1.171.56 1.706.945l1.591-.48c.681-.203 1.424.076 1.768.657l1.447 2.39c.347.592.23 1.321-.28 1.78l-1.234 1.081a8.004 8.004 0 010 1.884l1.228 1.076a1.404 1.404 0 01.29 1.776l-1.456 2.404c-.34.575-1.08.854-1.76.651l-1.594-.48a8.146 8.146 0 01-1.707.945l-.368 1.562c-.155.662-.758 1.124-1.467 1.124zM7.14 17.05c.15 0 .298.048.417.142.598.47 1.253.833 1.946 1.077a.636.636 0 01.412.45l.444 1.883c.02.085.1.148.19.148h2.905c.09 0 .17-.063.19-.148l.444-1.883a.635.635 0 01.413-.449 6.824 6.824 0 001.945-1.078.677.677 0 01.614-.114l1.922.58c.09.026.188-.004.231-.078l1.457-2.403a.196.196 0 00-.043-.241l-1.474-1.293a.605.605 0 01-.203-.554 6.946 6.946 0 000-2.178.61.61 0 01.203-.553L20.63 9.06a.196.196 0 00.035-.245l-1.447-2.39c-.048-.079-.142-.11-.238-.083l-1.92.579a.677.677 0 01-.613-.114 6.802 6.802 0 00-1.945-1.078.636.636 0 01-.413-.45l-.444-1.883a.203.203 0 00-.193-.147h-2.904c-.09 0-.17.063-.19.147l-.445 1.885a.635.635 0 01-.411.449 6.803 6.803 0 00-1.946 1.077.68.68 0 01-.614.115l-1.921-.58c-.091-.026-.19.005-.232.078L3.333 8.823a.196.196 0 00.044.243l1.473 1.291c.16.141.237.348.204.554A7.045 7.045 0 004.966 12c0 .356.03.722.088 1.09a.61.61 0 01-.204.553l-1.477 1.296a.196.196 0 00-.035.245l1.448 2.39a.2.2 0 00.237.083l1.92-.579a.665.665 0 01.197-.029z"
      ></path>
      <path
        className={styles}
        d="M11.002 15a1.76 1.76 0 01-.227-.014c-.537-.07-.873-.372-.75-.675l2.001-4.875c.124-.303.668-.49 1.199-.422.537.07.873.372.75.674l-2.001 4.875c-.107.261-.517.437-.972.437zM15.563 14a.595.595 0 01-.419-.165.463.463 0 01.042-.707L16.596 12l-1.41-1.128a.463.463 0 01-.042-.707.615.615 0 01.795-.037l1.875 1.5A.48.48 0 0118 12a.48.48 0 01-.186.372l-1.875 1.5a.603.603 0 01-.376.128zM8.437 14a.603.603 0 01-.376-.128l-1.875-1.5A.479.479 0 016 12c0-.141.067-.277.186-.372l1.875-1.5a.614.614 0 01.795.038.463.463 0 01-.042.706L7.404 12l1.41 1.128c.23.185.25.5.042.707a.595.595 0 01-.419.165z"
      ></path>
    </svg>
  );
}

function WorlflowsIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        fillRule="evenodd"
        d="M9 11.73v1.54c0 .459.219.899.608 1.223.39.325.918.507 1.469.507h1.846c.551 0 1.08-.182 1.469-.507.39-.325.608-.764.608-1.224v-1.538c0-.46-.219-.9-.608-1.224A2.304 2.304 0 0012.923 10h-1.846c-.551 0-1.08.182-1.469.507-.39.325-.608.764-.608 1.224zm1.385 0c0-.152.072-.3.203-.407a.764.764 0 01.489-.17h1.846c.184 0 .36.062.49.17a.53.53 0 01.202.408v1.538a.53.53 0 01-.203.408.764.764 0 01-.489.17h-1.846a.764.764 0 01-.49-.17.53.53 0 01-.202-.408v-1.538zM2 4.423v2.154c0 .643.219 1.259.608 1.713.39.455.918.71 1.469.71h1.846c.551 0 1.08-.255 1.469-.71.39-.454.608-1.07.608-1.713V4.423c0-.643-.219-1.259-.608-1.713-.39-.455-.918-.71-1.469-.71H4.077c-.551 0-1.08.255-1.469.71C2.218 3.164 2 3.78 2 4.423zm1.385 0c0-.214.073-.42.203-.57a.644.644 0 01.489-.238h1.846c.184 0 .36.085.49.237.13.151.202.357.202.571v2.154c0 .214-.073.42-.203.57a.644.644 0 01-.489.238H4.077a.644.644 0 01-.49-.237.877.877 0 01-.202-.571V4.423zM16 18.077v1.846A2.076 2.076 0 0018.077 22h1.846A2.076 2.076 0 0022 19.923v-1.846A2.076 2.076 0 0019.923 16h-1.846A2.076 2.076 0 0016 18.077zm1.385 0a.69.69 0 01.203-.49.69.69 0 01.489-.202h1.846a.69.69 0 01.49.203.69.69 0 01.202.489v1.846a.69.69 0 01-.203.49.69.69 0 01-.489.202h-1.846a.69.69 0 01-.49-.203.69.69 0 01-.202-.489v-1.846z"
        clipRule="evenodd"
      ></path>
      <path
        className={styles}
        fillRule="evenodd"
        d="M7.682 6.333H19.5c.302 0 .59.118.804.326a1.1 1.1 0 01.332.785v3.112a1.1 1.1 0 01-.332.785 1.151 1.151 0 01-.804.326h-2.727a.675.675 0 00-.682.666c0 .368.305.667.682.667H19.5c.663 0 1.3-.258 1.768-.716.468-.458.732-1.08.732-1.728V7.444c0-.648-.264-1.27-.732-1.728A2.534 2.534 0 0019.5 5H7.682A.675.675 0 007 5.667c0 .368.305.666.682.666zM12.34 18.667H4.42c-.292 0-.571-.118-.778-.326a1.119 1.119 0 01-.322-.785v-3.112c0-.295.116-.576.322-.785.207-.208.486-.326.778-.326h4.84c.364 0 .66-.298.66-.666A.664.664 0 009.26 12H4.42c-.642 0-1.258.258-1.712.716A2.461 2.461 0 002 14.444v3.112c0 .648.255 1.27.708 1.728.454.458 1.07.716 1.712.716h7.92c.364 0 .66-.299.66-.667a.664.664 0 00-.66-.666z"
        clipRule="evenodd"
      ></path>
      <path
        className={styles}
        fillRule="evenodd"
        d="M18.812 13.07L17.552 12l1.26-1.07a.489.489 0 000-.77.726.726 0 00-.91 0l-1.714 1.454a.489.489 0 000 .771l1.715 1.455c.25.213.658.213.909 0a.489.489 0 000-.77zM12.097 21.8l1.715-1.818a.71.71 0 000-.964L12.097 17.2a.617.617 0 00-.909 0 .71.71 0 000 .963l1.26 1.337-1.26 1.337a.71.71 0 000 .963c.25.267.659.267.91 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

function ProvidersIcons({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        d="M17.616 14.18c-1.53 0-2.774 1.306-2.774 2.91 0 1.605 1.245 2.91 2.774 2.91s2.773-1.305 2.773-2.91c0-1.604-1.244-2.91-2.773-2.91zm0 4.365c-.765 0-1.387-.652-1.387-1.455 0-.802.622-1.454 1.387-1.454.764 0 1.386.652 1.386 1.454 0 .803-.622 1.455-1.386 1.455zM8.14 14.18c-1.528 0-2.772 1.306-2.772 2.91 0 1.605 1.244 2.91 2.773 2.91 1.529 0 2.773-1.305 2.773-2.91 0-1.604-1.244-2.91-2.773-2.91zm0 4.365c-.764 0-1.386-.652-1.386-1.455 0-.802.622-1.454 1.387-1.454.764 0 1.386.652 1.386 1.454 0 .803-.622 1.455-1.386 1.455zM19.483 5.855a.69.69 0 00-.62-.4h-3.65v1.454h3.223l1.888 3.94 1.239-.654-2.08-4.34z"
      ></path>
      <path
        className={styles}
        d="M15.536 16.388H10.29v1.455h5.246v-1.455zM6.061 16.388H3.658c-.383 0-.694.326-.694.727 0 .402.31.728.694.728H6.06c.383 0 .693-.326.693-.728 0-.401-.31-.727-.693-.727zM22.854 11.943L21.491 10.1a.681.681 0 00-.548-.28h-5.038V4.726c0-.401-.31-.727-.693-.727H3.658c-.383 0-.694.326-.694.727 0 .402.31.728.694.728h10.86v5.091c0 .402.311.728.694.728h5.392l1.01 1.364v3.752h-1.919c-.383 0-.693.325-.693.727 0 .402.31.727.693.727h2.612c.383 0 .693-.325.693-.727V12.39a.751.751 0 00-.146-.446zM6.015 12.707h-3.19c-.382 0-.693.325-.693.727 0 .402.31.728.694.728h3.189c.383 0 .693-.326.693-.728 0-.401-.31-.727-.693-.727zM7.61 9.845H1.692c-.383 0-.693.326-.693.727 0 .402.31.728.693.728H7.61c.383 0 .693-.326.693-.728 0-.401-.31-.727-.693-.727z"
      ></path>
      <path
        className={styles}
        d="M8.742 6.983H2.826c-.383 0-.694.326-.694.728 0 .401.31.727.694.727h5.916c.383 0 .693-.326.693-.727 0-.402-.31-.728-.693-.728z"
      ></path>
    </svg>
  );
}
function StoresIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        fillRule="evenodd"
        d="M2.935 10.248c0-.397.304-.72.678-.72.375 0 .678.323.678.72v8.155c0 1.192.91 2.158 2.035 2.158h10.848c1.124 0 2.035-.966 2.035-2.159v-8.154c0-.397.303-.72.678-.72.374 0 .678.323.678.72v8.155c0 1.986-1.518 3.597-3.39 3.597H6.324c-1.872 0-3.39-1.61-3.39-3.598v-8.154z"
        clipRule="evenodd"
      ></path>
      <path
        className={styles}
        fillRule="evenodd"
        d="M11.75 13.366c.9 0 1.761.379 2.398 1.053.635.676.992 1.59.992 2.544v4.317c0 .398-.304.72-.678.72-.374 0-.678-.322-.678-.72v-4.317c0-.572-.214-1.121-.596-1.526a1.976 1.976 0 00-1.438-.632c-.54 0-1.057.227-1.438.632a2.227 2.227 0 00-.596 1.527v4.317c0 .397-.304.719-.678.719-.374 0-.678-.322-.678-.72v-4.317c0-.954.357-1.868.992-2.544a3.296 3.296 0 012.398-1.053zM8.987 2.72v5.396c0 2.026-1.55 3.67-3.459 3.67-1.076 0-2.094-.527-2.756-1.427a3.89 3.89 0 01-.637-3.183l.719-3.05C3.149 2.876 4.207 2 5.42 2h2.89c.373 0 .677.322.677.72zm-1.356.719H5.42c-.591 0-1.107.427-1.25 1.035l-.718 3.05a2.381 2.381 0 00.388 1.95c.406.55 1.03.872 1.688.872 1.161 0 2.103-.998 2.103-2.23V3.439zM15.191 2h2.89c1.213 0 2.27.876 2.565 2.126l.719 3.05a3.89 3.89 0 01-.637 3.183c-.662.9-1.68 1.426-2.756 1.426-1.91 0-3.46-1.643-3.46-3.67V2.72c0-.398.305-.72.679-.72zm.678 1.439v4.677c0 1.232.942 2.23 2.103 2.23.659 0 1.282-.322 1.688-.873.405-.551.549-1.27.389-1.948l-.72-3.05c-.142-.61-.657-1.036-1.249-1.036H15.87z"
        clipRule="evenodd"
      ></path>
      <path
        className={styles}
        fillRule="evenodd"
        d="M8.302 2h6.889a.66.66 0 01.48.211.743.743 0 01.198.51l-.005 5.218c-.002 2.125-1.625 3.846-3.627 3.846h-.985c-2.004 0-3.628-1.723-3.628-3.849V2.72c0-.398.304-.72.678-.72zm.678 1.439v4.497c0 1.331 1.017 2.41 2.272 2.41h.985c1.254 0 2.27-1.077 2.271-2.408l.005-4.499H8.98z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
function AnalyticsIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        fillRule="evenodd"
        d="M2 21c0-.552.497-1 1.111-1H20.89c.614 0 1.111.448 1.111 1s-.497 1-1.111 1H3.11C2.497 22 2 21.552 2 21zM18 2c-.552 0-1 .48-1 1.071V15.93c0 .591.448 1.071 1 1.071s1-.48 1-1.071V3.07C19 2.48 18.552 2 18 2zm-8 2.143c-.552 0-1 .48-1 1.071V15.93C9 16.52 9.448 17 10 17s1-.48 1-1.071V5.214c0-.591-.448-1.071-1-1.071zM6 10.57c-.552 0-1 .48-1 1.072v4.286C5 16.52 5.448 17 6 17s1-.48 1-1.071v-4.286c0-.592-.448-1.072-1-1.072zm7-3.214c0-.592.448-1.071 1-1.071s1 .48 1 1.071v8.572C15 16.52 14.552 17 14 17s-1-.48-1-1.071V7.357z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
function OrderManagementIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        d="M19.333 14.969V5.125c0-.862-.747-1.563-1.666-1.563h-.709v1.041c.256.215.417.527.417.874 0 .647-.56 1.171-1.25 1.171s-1.25-.524-1.25-1.171c0-.347.161-.66.417-.874v-1.04h-2.5v1.04c.255.215.416.527.416.874 0 .647-.56 1.171-1.25 1.171s-1.25-.524-1.25-1.171c0-.347.161-.66.417-.874v-1.04h-2.5V4.64c.231.213.375.509.375.837 0 .647-.56 1.171-1.25 1.171S6.5 6.124 6.5 5.477c0-.366.179-.692.458-.907V3.562h-.625c-.919 0-1.666.701-1.666 1.563v13.75c0 .862.747 1.563 1.666 1.563h11.334c.919 0 1.666-.701 1.666-1.563 0-.431.373-.781.834-.781.46 0 .833.35.833.781C21 20.598 19.505 22 17.667 22H6.333C4.495 22 3 20.598 3 18.875V5.125C3 3.402 4.495 2 6.333 2h11.334C19.505 2 21 3.402 21 5.125v9.844c0 .431-.373.781-.833.781-.46 0-.834-.35-.834-.781zm-12.169.781c-.46 0-.833.35-.833.781 0 .432.373.782.833.782h6.753c.46 0 .833-.35.833-.782 0-.431-.373-.781-.833-.781H7.164zm10.503-2.344c0-.431-.373-.781-.834-.781H7.164c-.46 0-.833.35-.833.781 0 .432.373.781.833.781h9.67c.46 0 .833-.35.833-.78zM16.833 9.5H7.164c-.46 0-.833.35-.833.781 0 .432.373.781.833.781h9.67c.46 0 .833-.35.833-.78 0-.432-.373-.782-.834-.782z"
      ></path>
    </svg>
  );
}
function AccountsIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        d="M6 18.4H4.5V5.6H6V4H3v16h3v-1.6zM18 5.6h1.5v12.8H18V20h3V4h-3v1.6zM6.75 7.2h1.5v1.6h-1.5V7.2zM11.25 7.2h1.5v1.6h-1.5V7.2zM15.75 7.2h1.5v1.6h-1.5V7.2zM6.75 11.2h1.5v1.6h-1.5v-1.6zM11.25 11.2h1.5v1.6h-1.5v-1.6zM15.75 11.2h1.5v1.6h-1.5v-1.6zM6.75 15.2h1.5v1.6h-1.5v-1.6zM11.25 15.2h1.5v1.6h-1.5v-1.6zM15.75 15.2h1.5v1.6h-1.5v-1.6z"
      ></path>
    </svg>
  );
}
function HomeIcon({ styles }) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={isdesktop ? "24" : "30"}
      height={isdesktop ? "24" : "30"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        className={styles}
        d="M20.198 21H14.35a.768.768 0 01-.764-.766v-3.64c0-.92-.726-1.648-1.643-1.648-.918 0-1.644.728-1.644 1.647v3.64a.768.768 0 01-.764.767h-5.77A.768.768 0 013 20.234v-9.81c0-.69.344-1.341.879-1.724l7.681-5.556a.744.744 0 01.88 0L20.12 8.7c.535.383.879 1.034.879 1.724v9.848a.825.825 0 01-.802.728zm-5.083-1.494h4.356v-9.082a.655.655 0 00-.267-.498l-7.223-5.25-7.223 5.212a.656.656 0 00-.268.498v9.081h4.357v-2.874c0-1.724 1.414-3.142 3.134-3.142s3.134 1.418 3.134 3.142v2.913z"
      ></path>
    </svg>
  );
}
function OrdersIcon({ styles }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        className={styles}
        d="M19.333 14.969V5.125c0-.862-.747-1.563-1.666-1.563h-.709v1.041c.256.215.417.527.417.874 0 .647-.56 1.171-1.25 1.171s-1.25-.524-1.25-1.171c0-.347.161-.66.417-.874v-1.04h-2.5v1.04c.255.215.416.527.416.874 0 .647-.56 1.171-1.25 1.171s-1.25-.524-1.25-1.171c0-.347.161-.66.417-.874v-1.04h-2.5V4.64c.231.213.375.509.375.837 0 .647-.56 1.171-1.25 1.171S6.5 6.124 6.5 5.477c0-.366.179-.692.458-.907V3.562h-.625c-.919 0-1.666.701-1.666 1.563v13.75c0 .862.747 1.563 1.666 1.563h11.334c.919 0 1.666-.701 1.666-1.563 0-.431.373-.781.834-.781.46 0 .833.35.833.781C21 20.598 19.505 22 17.667 22H6.333C4.495 22 3 20.598 3 18.875V5.125C3 3.402 4.495 2 6.333 2h11.334C19.505 2 21 3.402 21 5.125v9.844c0 .431-.373.781-.833.781-.46 0-.834-.35-.834-.781zm-12.169.781c-.46 0-.833.35-.833.781 0 .432.373.782.833.782h6.753c.46 0 .833-.35.833-.782 0-.431-.373-.781-.833-.781H7.164zm10.503-2.344c0-.431-.373-.781-.834-.781H7.164c-.46 0-.833.35-.833.781 0 .432.373.781.833.781h9.67c.46 0 .833-.35.833-.78zM16.833 9.5H7.164c-.46 0-.833.35-.833.781 0 .432.373.781.833.781h9.67c.46 0 .833-.35.833-.78 0-.432-.373-.782-.834-.782z"
      ></path>
    </svg>
  );
}
function ReviewIcon({ styles }) {
  const { Colors } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 30 30">
      <path
        className={styles}
        fill={Colors.fontDark}
        d="M26.886 26.738l-2.437-2.445a5.635 5.635 0 10-2.652 2.651l2.444 2.438a1.871 1.871 0 002.648-2.646l-.003.002zm-10.605-1.771a4.375 4.375 0 116.188-6.188 4.375 4.375 0 01-6.188 6.188zm9.72 3.53a.625.625 0 01-.88 0l-2.238-2.232c.329-.259.625-.555.884-.883L26 27.622a.62.62 0 010 .88v-.004z"
      ></path>
      <path
        className={styles}
        fill={Colors.fontDark}
        d="M15.625 27.5H7.5a1.875 1.875 0 01-1.875-1.875V4.375A1.875 1.875 0 017.5 2.5h10.863c.13 0 .26.014.387.042V6.25a1.875 1.875 0 001.875 1.875h3.702c.03.133.047.27.048.407v8.343a.625.625 0 101.25 0V8.532a3.11 3.11 0 00-.91-2.204L20.578 2.17a3.103 3.103 0 00-2.215-.921H7.5a3.129 3.129 0 00-3.125 3.125v21.25A3.129 3.129 0 007.5 28.75h8.125a.625.625 0 100-1.25zm7.871-20.625h-2.871A.625.625 0 0120 6.25V3.363l3.496 3.512z"
      ></path>
      <path
        className={styles}
        fill={Colors.fontDark}
        d="M8.75 8.125h6.875a.625.625 0 100-1.25H8.75a.625.625 0 000 1.25zM21.25 10.625H10.625a.624.624 0 100 1.25H21.25a.624.624 0 100-1.25zM21.875 14.375a.624.624 0 00-.625-.625H8.75a.625.625 0 100 1.25h12.5a.624.624 0 00.625-.625zM13.125 17.5a.624.624 0 00-.625-.625H8.75a.625.625 0 100 1.25h3.75a.624.624 0 00.625-.625zM8.75 21.25h2.5a.624.624 0 100-1.25h-2.5a.625.625 0 100 1.25zM8.75 24.375h3.125a.624.624 0 100-1.25H8.75a.625.625 0 100 1.25zM19.375 23.75a1.875 1.875 0 01-1.875-1.875.624.624 0 10-1.25 0A3.129 3.129 0 0019.375 25a.624.624 0 100-1.25z"
      ></path>
    </svg>
  );
}

function ReferralsIcon({ styles }) {
  const { Colors } = useTheme();

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 20 20">
      <g fill={Colors.fontDark} clipPath="url(#clip0_17466_7883)">
        <path
          className={styles}
          d="M.586 20h5.98a.586.586 0 00.585-.586v-.653a3.579 3.579 0 00-1.87-3.142c.395-.42.638-.985.638-1.605a2.346 2.346 0 00-2.343-2.344 2.346 2.346 0 00-2.344 2.344c0 .62.243 1.185.638 1.605A3.579 3.579 0 000 18.761v.653c0 .324.262.586.586.586zm1.818-5.986c0-.646.526-1.172 1.172-1.172.646 0 1.172.525 1.172 1.172a1.173 1.173 0 01-2.344 0zm1.172 2.343c1.255 0 2.404.985 2.404 2.471H1.172c0-1.479 1.142-2.47 2.404-2.47zM18.13 15.619c.395-.42.638-.985.638-1.605a2.346 2.346 0 00-2.344-2.344 2.346 2.346 0 00-2.343 2.344c0 .62.243 1.185.638 1.605a3.579 3.579 0 00-1.87 3.142v.653c0 .324.262.586.586.586h5.98a.586.586 0 00.585-.586v-.653a3.579 3.579 0 00-1.87-3.142zm-2.878-1.605c0-.646.526-1.172 1.172-1.172.646 0 1.172.525 1.172 1.172a1.173 1.173 0 01-2.344 0zm3.576 4.814h-4.807c0-1.479 1.141-2.47 2.403-2.47 1.256 0 2.404.983 2.404 2.47zM13.576 7.744v-.653a3.579 3.579 0 00-1.87-3.142c.395-.42.638-.984.638-1.605A2.346 2.346 0 0010 0a2.346 2.346 0 00-2.344 2.344c0 .62.243 1.185.639 1.605a3.579 3.579 0 00-1.87 3.142v.653c0 .324.262.586.585.586h5.98a.586.586 0 00.586-.586zm-4.748-5.4c0-.646.526-1.172 1.172-1.172.646 0 1.172.526 1.172 1.172 0 .646-.526 1.172-1.172 1.172a1.173 1.173 0 01-1.172-1.172zm3.576 4.814H7.596c0-1.479 1.142-2.47 2.404-2.47 1.255 0 2.404.983 2.404 2.47zM12.367 14.807a7.673 7.673 0 01-2.399.379c-.797 0-1.578-.12-2.32-.354a.586.586 0 10-.354 1.117 8.858 8.858 0 002.674.409c.95 0 1.881-.148 2.765-.438a.586.586 0 00-.366-1.113zM4.6 4.855a8.856 8.856 0 00-1.636 2.156 8.844 8.844 0 00-.935 2.638.586.586 0 001.153.21 7.675 7.675 0 01.812-2.289c.38-.7.858-1.33 1.418-1.87a.586.586 0 00-.813-.845zM15.401 4.855a.586.586 0 10-.813.844c.56.54 1.038 1.17 1.418 1.871.395.726.668 1.497.812 2.29a.586.586 0 101.153-.21 8.846 8.846 0 00-.935-2.64 8.857 8.857 0 00-1.635-2.155z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_17466_7883">
          <path className={styles} fill={Colors.fontDark} d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

function FeedbackFormIllustration() {
  return (
    <svg width="97" height="96" viewBox="0 0 97 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M37.9771 24.651C36.3706 25.5788 34.1506 25.4447 31.6985 24.0291C26.7949 21.1979 22.8195 14.3121 22.8195 8.65012C22.819 5.81846 23.813 3.82964 25.42 2.90185C26.3956 2.33847 28.4488 1.15403 29.4245 0.590646C31.0314 -0.337143 33.2514 -0.202558 35.703 1.2126C40.6071 4.04426 44.5821 10.9296 44.5825 16.592C44.5825 19.4236 43.5886 21.4125 41.9816 22.3398C41.006 22.9032 38.9527 24.0876 37.9771 24.651Z"
        fill="#66A1FF"
      />
      <path
        opacity="0.3"
        d="M37.9771 24.651C36.3706 25.5788 34.1506 25.4447 31.6985 24.0291C26.7949 21.1979 22.8195 14.3121 22.8195 8.65012C22.819 5.81846 23.813 3.82964 25.42 2.90185C26.3956 2.33847 28.4488 1.15403 29.4245 0.590646C31.0314 -0.337143 33.2514 -0.202558 35.703 1.2126C40.6071 4.04426 44.5821 10.9296 44.5825 16.592C44.5825 19.4236 43.5886 21.4125 41.9816 22.3398C41.006 22.9032 38.9527 24.0876 37.9771 24.651Z"
        fill="white"
      />
      <path
        d="M22.8196 8.6497C22.8196 5.81849 23.8135 3.82923 25.4201 2.90144C27.027 1.97365 29.2466 2.10779 31.6986 3.52339C36.6027 6.35505 40.5781 13.2404 40.5781 18.9028C40.5781 21.734 39.5842 23.7233 37.9776 24.6511C36.3707 25.5788 34.1511 25.4447 31.6991 24.0291C26.795 21.1974 22.8196 14.3121 22.8196 8.6497Z"
        fill="#66A1FF"
      />
      <path
        opacity="0.5"
        d="M22.8196 8.6497C22.8196 5.81849 23.8135 3.82923 25.4201 2.90144C27.027 1.97365 29.2466 2.10779 31.6986 3.52339C36.6027 6.35505 40.5781 13.2404 40.5781 18.9028C40.5781 21.734 39.5842 23.7233 37.9776 24.6511C36.3707 25.5788 34.1511 25.4447 31.6991 24.0291C26.795 21.1974 22.8196 14.3121 22.8196 8.6497Z"
        fill="white"
      />
      <path
        d="M36.291 16.7722C36.291 18.152 35.9306 19.2681 35.3243 19.9888C34.4806 20.9998 33.171 21.2457 31.703 20.3931C30.2266 19.5404 28.917 17.7738 28.0733 15.7966C27.4665 14.3729 27.1066 12.8433 27.1066 11.4635L36.291 16.7722Z"
        fill="#37474F"
      />
      <path
        d="M35.3242 19.9888C34.4805 20.9997 33.1709 21.2457 31.7029 20.393C30.2265 19.5403 28.9169 17.7737 28.0732 15.7965C29.0135 15.1196 30.2967 15.1111 31.7029 15.9195C33.1007 16.7279 34.3839 18.2132 35.3242 19.9888Z"
        fill="#66A1FF"
      />
      <path
        d="M34.2628 11.7282C34.2628 12.4109 34.6223 13.1724 35.0659 13.4286C35.5094 13.6848 35.8689 13.3387 35.8689 12.6555C35.8689 11.9727 35.5094 11.2113 35.0659 10.9551C34.6223 10.6993 34.2628 11.045 34.2628 11.7282Z"
        fill="#37474F"
      />
      <path
        d="M27.5636 7.99332C27.5636 8.67608 27.9231 9.43754 28.3666 9.69374C28.8102 9.94994 29.1697 9.60387 29.1697 8.92066C29.1697 8.23789 28.8102 7.47644 28.3666 7.22023C27.9231 6.96448 27.5636 7.31011 27.5636 7.99332Z"
        fill="#37474F"
      />
      <path
        d="M61.1861 38.0505C59.5796 38.9783 57.3596 38.8442 54.9075 37.4286C50.0039 34.5974 46.0285 27.7116 46.0285 22.0496C46.028 19.218 47.022 17.2287 48.629 16.3014C49.6046 15.738 51.6578 14.5535 52.6334 13.9902C54.2404 13.0624 56.4604 13.197 58.912 14.6121C63.8161 17.4438 67.7911 24.3295 67.7915 29.992C67.7915 32.8232 66.7975 34.8124 65.1906 35.7398C64.2149 36.3027 62.1617 37.4872 61.1861 38.0505Z"
        fill="#66A1FF"
      />
      <path
        opacity="0.3"
        d="M61.1861 38.0505C59.5796 38.9783 57.3596 38.8442 54.9075 37.4286C50.0039 34.5974 46.0285 27.7116 46.0285 22.0496C46.028 19.218 47.022 17.2287 48.629 16.3014C49.6046 15.738 51.6578 14.5535 52.6334 13.9902C54.2404 13.0624 56.4604 13.197 58.912 14.6121C63.8161 17.4438 67.7911 24.3295 67.7915 29.992C67.7915 32.8232 66.7975 34.8124 65.1906 35.7398C64.2149 36.3027 62.1617 37.4872 61.1861 38.0505Z"
        fill="white"
      />
      <path
        d="M46.0281 22.0492C46.0281 19.218 47.022 17.2287 48.6286 16.301C50.2355 15.3732 52.4551 15.5073 54.9071 16.9234C59.8112 19.7546 63.7866 26.6403 63.7866 32.3027C63.7866 35.134 62.7927 37.1232 61.1861 38.051C59.5791 38.9788 57.3596 38.8447 54.9076 37.4286C50.0035 34.597 46.0281 27.7116 46.0281 22.0492Z"
        fill="#66A1FF"
      />
      <path
        opacity="0.5"
        d="M46.0281 22.0492C46.0281 19.218 47.022 17.2287 48.6286 16.301C50.2355 15.3732 52.4551 15.5073 54.9071 16.9234C59.8112 19.7546 63.7866 26.6403 63.7866 32.3027C63.7866 35.134 62.7927 37.1232 61.1861 38.051C59.5791 38.9788 57.3596 38.8447 54.9076 37.4286C50.0035 34.597 46.0281 27.7116 46.0281 22.0492Z"
        fill="white"
      />
      <path
        d="M59.3896 30.6014C59.2399 31.4084 58.9425 32.0756 58.533 32.562C57.6892 33.573 56.3796 33.8189 54.9117 32.9662C53.4353 32.1136 52.1256 30.347 51.2819 28.3698C50.8732 27.4107 50.5763 26.4033 50.4261 25.4263"
        stroke="#37474F"
        stroke-width="3.4641"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M57.4716 25.1277C57.4716 25.8104 57.8311 26.5719 58.2746 26.8281C58.7182 27.0843 59.0776 26.7382 59.0776 26.055C59.0776 25.3722 58.7182 24.6108 58.2746 24.3546C57.8311 24.0988 57.4716 24.4444 57.4716 25.1277Z"
        fill="#37474F"
      />
      <path
        d="M50.7721 21.3928C50.7721 22.0756 51.1316 22.837 51.5751 23.0932C52.0187 23.3494 52.3782 23.0034 52.3782 22.3206C52.3782 21.6378 52.0187 20.8764 51.5751 20.6202C51.132 20.364 50.7721 20.71 50.7721 21.3928Z"
        fill="#37474F"
      />
      <path
        d="M84.3946 51.45C82.7881 52.3778 80.5681 52.2437 78.116 50.8281C73.2124 47.9969 69.237 41.1111 69.237 35.4491C69.2365 32.6175 70.2305 30.6282 71.8375 29.7004C72.8131 29.137 74.8663 27.9526 75.8419 27.3892C77.4489 26.4614 79.6689 26.596 82.1205 28.0112C87.0246 30.8428 91 37.7286 91 43.3906C91 46.2222 90.006 48.2111 88.3991 49.1384C87.4234 49.7022 85.3702 50.8867 84.3946 51.45Z"
        fill="#66A1FF"
      />
      <path
        opacity="0.3"
        d="M84.3946 51.45C82.7881 52.3778 80.5681 52.2437 78.116 50.8281C73.2124 47.9969 69.237 41.1111 69.237 35.4491C69.2365 32.6175 70.2305 30.6282 71.8375 29.7004C72.8131 29.137 74.8663 27.9526 75.8419 27.3892C77.4489 26.4614 79.6689 26.596 82.1205 28.0112C87.0246 30.8428 91 37.7286 91 43.3906C91 46.2222 90.006 48.2111 88.3991 49.1384C87.4234 49.7022 85.3702 50.8867 84.3946 51.45Z"
        fill="white"
      />
      <path
        d="M69.2366 35.4487C69.2366 32.6175 70.2305 30.6282 71.8371 29.7004C73.444 28.7726 75.6636 28.9067 78.1156 30.3228C83.0197 33.154 86.9951 40.0398 86.9951 45.7022C86.9951 48.5334 86.0012 50.5227 84.3946 51.4505C82.7877 52.3782 80.5681 52.2441 78.1161 50.8281C73.2124 47.9968 69.2366 41.1111 69.2366 35.4487Z"
        fill="#66A1FF"
      />
      <path
        opacity="0.5"
        d="M69.2366 35.4487C69.2366 32.6175 70.2305 30.6282 71.8371 29.7004C73.444 28.7726 75.6636 28.9067 78.1156 30.3228C83.0197 33.154 86.9951 40.0398 86.9951 45.7022C86.9951 48.5334 86.0012 50.5227 84.3946 51.4505C82.7877 52.3782 80.5681 52.2441 78.1161 50.8281C73.2124 47.9968 69.2366 41.1111 69.2366 35.4487Z"
        fill="white"
      />
      <path
        d="M73.981 41.0589L82.2864 45.8601"
        stroke="#37474F"
        stroke-width="3.4641"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M80.6803 38.5272C80.6803 39.2099 81.0398 39.9714 81.4833 40.2276C81.9269 40.4838 82.2864 40.1377 82.2864 39.4545C82.2864 38.7717 81.9269 38.0103 81.4833 37.7541C81.0398 37.4983 80.6803 37.8444 80.6803 38.5272Z"
        fill="#37474F"
      />
      <path
        d="M73.9808 34.7923C73.9808 35.4751 74.3403 36.2365 74.7839 36.4927C75.2274 36.7489 75.5869 36.4029 75.5869 35.7201C75.5869 35.0373 75.2274 34.2759 74.7839 34.0197C74.3403 33.7635 73.9808 34.1095 73.9808 34.7923Z"
        fill="#37474F"
      />
      <path
        d="M3.2897 23.6647C3.8437 23.3378 4.56849 23.3642 5.36303 23.823L111.844 85.2998C113.587 86.3063 115 89.0061 115 91.3302C115 92.5925 114.582 93.4827 113.922 93.8717C113.611 94.055 112.412 94.736 112.112 94.9162C111.557 95.2493 110.828 95.2252 110.029 94.7637L3.5486 33.2869C1.80569 32.2804 0.392319 29.5806 0.392319 27.2564C0.392319 26 0.805916 25.1125 1.46096 24.7208C1.7735 24.5339 2.98432 23.8449 3.2897 23.6647Z"
        fill="#E0E0E0"
      />
      <path
        opacity="0.1"
        d="M3.2897 23.6647C3.8437 23.3378 4.56849 23.3642 5.36303 23.823L111.844 85.2998C113.587 86.3063 115 89.0061 115 91.3302C115 92.5925 114.582 93.4827 113.922 93.8717C113.611 94.055 112.412 94.736 112.112 94.9162C111.557 95.2493 110.828 95.2252 110.029 94.7637L3.5486 33.2869C1.80569 32.2804 0.392319 29.5806 0.392319 27.2564C0.392319 26 0.805916 25.1125 1.46096 24.7208C1.7735 24.5339 2.98432 23.8449 3.2897 23.6647Z"
        fill="black"
      />
      <path
        d="M3.54764 33.2864L110.029 94.7633C111.772 95.7698 113.185 94.7016 113.185 92.3774C113.185 90.0532 111.772 87.3535 110.029 86.347L3.54764 24.8701C1.80474 23.8636 0.391373 24.9318 0.391373 27.256C0.391373 29.5802 1.80474 32.2799 3.54764 33.2864Z"
        fill="#E0E0E0"
      />
      <path
        d="M4.06136 31.6611L109.514 92.5442C110.334 93.0177 110.999 92.3778 110.999 91.1152C110.999 89.8525 110.334 88.4449 109.514 87.9714L4.06136 27.0883C3.24133 26.6148 2.57646 27.2546 2.57646 28.5173C2.57602 29.78 3.24089 31.1876 4.06136 31.6611Z"
        fill="#455A64"
      />
      <path
        d="M109.514 87.9718C110.335 88.4453 110.999 89.8529 110.999 91.1156C110.999 91.2412 110.991 91.3588 110.978 91.4719C110.954 91.4599 110.931 91.45 110.906 91.4362L5.45327 30.5531C4.63324 30.0796 3.96837 28.672 3.96837 27.4093C3.96837 27.2837 3.97686 27.1661 3.98938 27.0529C4.01308 27.065 4.03677 27.0749 4.06092 27.0887L109.514 87.9718Z"
        fill="#263238"
      />
      <path
        d="M110.036 92.2531C110.335 92.2531 110.552 91.7747 110.552 91.1152C110.552 90.0157 109.974 88.7534 109.291 88.3591L34.3624 45.0745C34.2533 45.0114 34.15 44.9783 34.0646 44.9783C33.765 44.9783 33.5482 45.4568 33.5482 46.1163C33.5482 47.2158 34.1259 48.478 34.8095 48.8724L109.738 92.157C109.847 92.22 109.95 92.2531 110.036 92.2531Z"
        fill="#66A1FF"
      />
      <path
        d="M33.9688 49.1974C33.5306 49.4505 32.9252 49.4138 32.2563 49.0275C30.9189 48.2553 29.8347 46.3773 29.8347 44.833C29.8347 44.0608 30.1056 43.518 30.5438 43.2649C30.8098 43.1111 30.9377 43.0373 31.2038 42.8835C31.6419 42.6304 32.2474 42.6671 32.9163 43.053C34.2536 43.8251 35.3379 45.7031 35.3379 47.2475C35.3379 48.0196 35.0665 48.562 34.6283 48.8151C34.3627 48.9698 34.2348 49.0436 33.9688 49.1974Z"
        fill="#263238"
      />
      <path
        d="M29.8347 44.833C29.8347 44.0608 30.1057 43.518 30.5438 43.2654C30.982 43.0123 31.5874 43.0489 32.2563 43.4353C33.5937 44.2075 34.678 46.0854 34.678 47.6298C34.678 48.402 34.407 48.9448 33.9688 49.1974C33.5306 49.4505 32.9252 49.4138 32.2563 49.0275C30.919 48.2557 29.8347 46.3774 29.8347 44.833Z"
        fill="#455A64"
      />
      <path
        d="M30.3669 45.1406C30.3669 44.5383 30.5784 44.1149 30.9204 43.9172C31.2625 43.7201 31.7346 43.7482 32.2564 44.0496C33.3 44.6519 34.146 46.1171 34.146 47.3221C34.146 47.9244 33.9345 48.3478 33.5924 48.5455C33.2504 48.7426 32.7782 48.7145 32.2564 48.4131C31.2128 47.8104 30.3669 46.3456 30.3669 45.1406Z"
        fill="#66A1FF"
      />
    </svg>
  );
}

function FeedbackIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.75 10.25C14.75 10.6478 14.592 11.0294 14.3107 11.3107C14.0294 11.592 13.6478 11.75 13.25 11.75H4.25L1.25 14.75V2.75C1.25 2.35218 1.40804 1.97064 1.68934 1.68934C1.97064 1.40804 2.35218 1.25 2.75 1.25H13.25C13.6478 1.25 14.0294 1.40804 14.3107 1.68934C14.592 1.97064 14.75 2.35218 14.75 2.75V10.25Z"
        stroke="#0062FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0999 4.625C9.8412 4.3667 9.49054 4.22162 9.12494 4.22162C8.75933 4.22162 8.40867 4.3667 8.14994 4.625L7.99994 4.85L7.77494 4.625C7.63705 4.50681 7.47723 4.41694 7.30461 4.36052C7.13199 4.30409 6.94994 4.28222 6.76887 4.29615C6.5878 4.31008 6.41124 4.35953 6.24928 4.4417C6.08732 4.52386 5.94313 4.63712 5.82494 4.775C5.70675 4.91289 5.61687 5.07271 5.56045 5.24533C5.50402 5.41795 5.48215 5.6 5.49608 5.78107C5.51001 5.96214 5.55946 6.1387 5.64163 6.30066C5.72379 6.46262 5.83705 6.60682 5.97494 6.725L7.99994 8.75L10.0249 6.725C10.6999 6.05 10.6249 5.15 10.0999 4.625Z"
        stroke="#0062FF"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export {
  AdminIcon,
  DevelopersIcon,
  WorlflowsIcon,
  ProvidersIcons,
  StoresIcon,
  AnalyticsIcon,
  OrderManagementIcon,
  AccountsIcon,
  HomeIcon,
  OrdersIcon,
  ReviewIcon,
  ReferralsIcon,
  FeedbackFormIllustration,
  FeedbackIcon,
};
