import React, { useEffect, useRef, useState } from "react";

import { useClickStore, useMyStore, useTheme } from "../../store";
import { FiDownload } from "react-icons/fi";
import CustomModal from "../CustomModal/CustomModal";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

import moment from "moment";
import { getPreviousMonthDate } from "../../utils/helpers";

import CSVModal from "./CSVModal";

const initFormatedDate = getPreviousMonthDate({ prevMonth: 0, isStart: true });

const initDate = {
  start_date: initFormatedDate.monthStart,
  end_date: moment().format("YYYY-MM-DD"),
};

const DownloadCsv = ({ admin }) => {
  const { isdesktop, smfontsize, xsfontsize } = useDesktopOrLaptop();

  const { Colors } = useTheme();

  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const [isCsvModalVisible, setIsCsvModalVisible] = useState(false);

  const { setIsCalendarHidden, setAllTimeVisible } = useClickStore();

  const { isSandbox } = useMyStore();

  const clickRef = useRef();

  useEffect(() => {
    stopper.current = false;
    setIsLoading(false);
  }, [isSandbox]);

  const [isLoading, setIsLoading] = useState(false);

  const [storeForCSV, setStoreForCSV] = useState();

  const [datePayload, setDatePayload] = useState(initDate);

  const stopper = useRef(false);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        position: "relative",
        marginRight: "6px",
      }}
      ref={clickRef}
    >
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isCsvModalVisible}
        onClose={() => {
          setIsCalendarHidden(false);
          setIsCsvModalVisible(false);
          setAllTimeVisible(true);
          setStoreForCSV();
          stopper.current = true;
        }}
      >
        <CSVModal
          stopper={stopper}
          admin={admin}
          datePayload={datePayload}
          initDate={initDate}
          isCsvModalVisible={isCsvModalVisible}
          isDownloadLoading={isDownloadLoading}
          isLoading={isLoading}
          setDatePayload={setDatePayload}
          setIsCsvModalVisible={setIsCsvModalVisible}
          setIsDownloadLoading={setIsDownloadLoading}
          setStoreForCSV={setStoreForCSV}
          storeForCSV={storeForCSV}
        />
      </CustomModal>

      <div
        style={{
          left: "-40px",
          top: isdesktop ? "44px" : "50px",
          backgroundColor: Colors.bgDarkSecondary,
          zIndex: 999,
          maxHeight: "400px",
          width: "150px",
        }}
        className={`${true ? "visible" : "hidden"} absolute kata overflow-y-scroll  rounded-md shadow-xl flex flex-col z-50 `}
      ></div>

      <button
        style={{
          marginLeft: "10px",
          color: Colors.fontGreyLight,
          display: "flex",
          alignItems: "center",
          padding: "0px 16px",
          height: isdesktop ? "42px" : "50px",
          borderRadius: "6px",
          border: `1px solid ${Colors.grayButton}`,
          backgroundColor: Colors.white,
        }}
        type="button"
        onClick={async () => {
          stopper.current = true;
          setIsCsvModalVisible(true);
          setIsCalendarHidden(true);
          setAllTimeVisible(false);
          setIsLoading(false);
          // setFilterStore();
          setStoreForCSV();
        }}
      >
        <FiDownload color={Colors.fontGreyLight} size={21} />{" "}
        <span
          style={{
            marginLeft: "6px",
            color: Colors.fontGreyLight,
            fontWeight: "500",
            fontSize: xsfontsize,
          }}
        >
          Export
        </span>
      </button>
    </div>
  );
};

export default DownloadCsv;
