import React, { useEffect, useState } from "react";
import styles from "./styles/styles.module.css";

import ImageCarousal from "./ImageCarousal";
import useViewportMeta from "../../customHooks/useViewportMeta";
import { Form, Formik } from "formik";
import { useMerchant, userDataStore, useTheme } from "../../store";
import { formValidationSchema, initialValues } from "./validationSchemas";
import { industries } from "./utils";
import CustomTextField from "./CustomTextField";
import CustomPhoneNumberField from "./CustomPhoneNumberField";
import classNames from "classnames";
import Hotjar from "@hotjar/browser";
import { useNavigate } from "react-router-dom";
import { X } from "lucide-react";
import { removeCookie } from "../../utils";
import { ZendeskAPI } from "react-zendesk";
import { baseUrl } from "../../runTimeConst";
import Cookies from "js-cookie";
import { useFlag } from "@unleash/proxy-client-react";

const BusinessForm = ({ loginHandler }) => {
  useViewportMeta();

  const [disableButton, setDisableButton] = useState(false);

  const { createMerchantData, merchantData } = useMerchant();
  const { authInfo } = userDataStore();

  const isMultiAccountsEnabled = useFlag("multiAccounts");
  const { Colors } = useTheme();

  const history = useNavigate();

  useEffect(() => {
    if (Hotjar.isReady()) {
      Hotjar.event("Start_Onboarding_Page");
    }
  }, []);

  const onFormSubmit = async (values) => {
    if (Hotjar.isReady()) {
      Hotjar.event("Complete_Onboarding");
    }
    setDisableButton(true);
    const res = await createMerchantData(values);
    if (res) {
      await loginHandler();
      history("/v1/home");
    }
    setDisableButton(false);
  };

  useEffect(() => {
    loginHandler();
  }, []);

  useEffect(() => {
    if (merchantData && !isMultiAccountsEnabled) {
      history("/v1/home");
    }
  }, [merchantData]);

  return (
    <div className={styles.container}>
      <div className={styles.imageSection}>
        <h2>Manage and track deliveries</h2>
        <p>
          Keep an eye on orders even after they’re out the door. Track orders in real time and easily send your customers a single
          link so they can do the same.
        </p>
        <ImageCarousal />
      </div>
      <div className={classNames(styles.formSection, "kata")}>
        <div className="flex items-start justify-between w-full mb-10">
          <h2 className="!m-0">Let's get to know your business</h2>
          <div
            className="cursor-pointer"
            onClick={() => {
              if (authInfo.merchantAccountId) {
                history(-1);
                return;
              }
              // window.heap.resetIdentity();
              Object.keys(Cookies.get()).forEach((cookieName) => {
                removeCookie(cookieName);
              });
              ZendeskAPI("messenger", "logoutUser");
              window.location.href = `${baseUrl}auth/logout`;
            }}
          >
            <X size={"24px"} className="mr-2 mt-4 text-black leading-[54px]" />
          </div>
        </div>
        <Formik onSubmit={onFormSubmit} validationSchema={formValidationSchema} initialValues={initialValues}>
          {({ handleSubmit, handleChange, handleBlur, values, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <label htmlFor="standardized_industry">Industry</label>
                <select
                  name="standardized_industry"
                  value={values.standardized_industry}
                  draggable={true}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  {industries.map((item) => {
                    return (
                      <option key={item.back} value={item.back}>
                        {item.front}
                      </option>
                    );
                  })}
                </select>

                <p>
                  Your industry choice will set the delivery providers available to you. Don’t worry, you can always change it
                  later in settings!
                </p>

                <label htmlFor="name">Business Name</label>
                <CustomTextField id="name" name="name" lable="name" placeholder="Enter your business name" type="text" />

                <label htmlFor="country">Country</label>
                <select
                  name="country"
                  value={values.country}
                  draggable={true}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option key="US" value="US">
                    USA
                  </option>
                  <option key="CA" value="CA">
                    Canada
                  </option>
                </select>
                <p>Once set, your country cannot be changed.</p>

                <label htmlFor="address">Business Address</label>
                <CustomTextField
                  id="address"
                  name="address"
                  address={true}
                  setFieldValue={setFieldValue}
                  lable="address"
                  placeholder="Enter your business address"
                  type="text"
                />

                <label htmlFor="phone-number">Phone Number</label>
                <CustomPhoneNumberField
                  name="phone_number"
                  buttonStyle={{
                    backgroundColor: Colors.bgSettings,
                    borderWidth: "1px",
                    borderColor: "#E2E2EA",
                    borderRadius: "10px",
                  }}
                  style={{
                    ...{
                      color: Colors.fontGreyLight,
                      backgroundColor: Colors.bgSettings,
                      border: `1px solid ${Colors.tableBorderGray}`,
                      paddingLeft: "48px",
                      marginBottom: "27px",
                    },
                  }}
                  containerStyles={{ height: "44px", width: "100%", marginBottom: "27px", position: "relative" }}
                  errorTextStyles={{ fontSize: "12px" }}
                  placeholder="+19712030805"
                  type="tel"
                  format="+1 (###) ###-####"
                  value={values.phone_number}
                />

                <label htmlFor="website">Business Website (optional)</label>
                <CustomTextField url="true" name="website" lable="website" placeholder="Enter your website" />

                <label htmlFor="tax_or_ein">Company Registration Number (optional)</label>
                <CustomTextField name="tax_or_ein" lable="tax_or_ein" placeholder="Tax Id or EIN" />

                <button type="submit" disabled={disableButton}>Go to dashboard</button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BusinessForm;
