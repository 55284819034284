import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  useRerouteRecommendations,
  useTheme,
} from "../../../store";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import ButtonComp from "../../ButtonComp/ButtonComp";
import StatusBadgeReusable from "../../shared/common/StatusBadgeReusable";
import { mapDeliveryType, mapRerouteIntentActionReasons, mapRerouteIntentActions } from "./utils";
import Details from "../Details";
import CustomModal from "../../CustomModal/CustomModal";
import { badgesForDeliveryStatuses } from "../DeliveriesForAdmin/utils";

const RerouteIntentItem = ({
  item,
  index,
  socketMessage,
}) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);

  const { isdesktop } = useDesktopOrLaptop();

  const { setSelectedRerouteIntent } = useRerouteRecommendations();

  const { Colors, themeChanger } = useTheme();
  const { xsfontsize } = useDesktopOrLaptop();
  const [rerouteIntent, setRerouteIntent] = useState(item);

  useEffect(() => {
    if (socketMessage?.id === item?.delivery_id) {
      setRerouteIntent({ ...item, delivery_status: socketMessage?.status });
    }
  }, [socketMessage]);

  return (
    <>
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "60%" : "80%" }}
        isOpen={isDetailsOpen}
        onClose={(e) => {
          setIsDetailsOpen(false);
        }}
      >
        <Details
          index={index}
          deliveryId={rerouteIntent?.delivery_id}
          onClose={() => {
            setIsDetailsOpen(false);
          }}
        />
      </CustomModal>
      <div
        style={{
          borderBottomWidth: "1.5px",
          borderColor: Colors.borderPrimary,
          fontWeight: "500",
          color: Colors.fontDark,
        }}
        className={`grid grid-cols-12 gap-1 w-full py-3 ${!themeChanger ? "hover:bg-gray-700" : "hover:bg-gray-100"
          }`}
      >
        <button
          type="button"
          style={{ color: Colors.bluePrimary }}
          onClick={() => {
            setIsDetailsOpen(true);
          }}
          className="col-start-1 col-span-1 underline break-all"
        >
          <div>{rerouteIntent.delivery_id}</div>
        </button>
        <div className="col-start-2 col-span-1 justify-center flex items-center flex-col break-all">
          <span>{moment(rerouteIntent.evaluate_at).format("MMM D")}</span>
          <span>{moment(rerouteIntent.evaluate_at).format("hh:mma")}</span>
        </div>
        <div className="col-start-3 col-span-1 justify-center flex items-center flex-col break-all">
          {rerouteIntent.action_proposed_at ?
            <>
              <span>{moment(rerouteIntent.action_proposed_at).format("MMM D")}</span>
              <span>{moment(rerouteIntent.action_proposed_at).format("hh:mma")}</span>
            </>
            : null}
        </div>
        <div className="col-start-4 col-span-2 justify-center flex items-center break-all">
          {rerouteIntent.merchant_email}
        </div>

        <div className="col-start-6 col-span-1 justify-center flex items-center text-center">
          {mapRerouteIntentActions[rerouteIntent.action]}
        </div>

        <div className="col-start-7 col-span-1 justify-center flex items-center text-center">
          {mapRerouteIntentActionReasons[rerouteIntent.action_reason]}
        </div>

        <div className="col-start-8 col-span-2 justify-center flex items-center text-center">
          {rerouteIntent.action_explanation}
        </div>

        <div className="col-start-10 col-span-1 justify-center flex items-center text-center">
          {mapDeliveryType[rerouteIntent.delivery_type]}
        </div>

        <div className="col-start-11 col-span-1 justify-center flex w-full items-center">
          {rerouteIntent.delivery_status && (
            <>
              <StatusBadgeReusable
                badgesObject={badgesForDeliveryStatuses}
                status={rerouteIntent.delivery_status}
              />
            </>
          )}
        </div>

        <div className="col-start-12 col-span-1 justify-center flex items-center">
          <ButtonComp
            onClick={async (e) => {
              e.stopPropagation();
              setSelectedRerouteIntent(rerouteIntent);
            }}
            primary
            title={"Review"}
            extraStyles={{
              padding: "8px 12px",
              fontSize: xsfontsize,
              fontWeight: "400",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default RerouteIntentItem;
