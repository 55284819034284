import React, { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import styles from "./styles.module.css";
import classNames from "classnames";

const CopyToClipboard = ({ copyText, setCopiedText, extraContainerClasses = "", tooltipAdjustmentClass = "", children }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleCopyClick = async () => {
    clearTimeout();
    navigator.clipboard.writeText(copyText).then(() => {
      if (setCopiedText) {
        setCopiedText(copyText);
      }
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 1600); // Hide tooltip after 2 seconds
    });
  };

  return (
    <div className={classNames(styles.copyIcon, extraContainerClasses)} onClick={handleCopyClick}>
      {children ? children : <MdContentCopy />}
      <div
        className={classNames(
          styles.tooltip,
          {
            [styles.fadeIn]: tooltipVisible,
            [styles.fadeOut]: !tooltipVisible,
          },
          tooltipAdjustmentClass
        )}
      >
        Copied!
      </div>
    </div>
  );
};
export default CopyToClipboard;
