import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useClickStore, useMerchant, useMyStore, useStoreAndChild, useTheme } from "../../../store";
import { canCreateDeliveriesService, errorMessagesSerive } from "../../../utils/helpers";
import { CirclePlus } from "lucide-react";
import ToolTip from "../../Tooltip/Tooltip";
import styles from "./styles.module.css";

const CreateDeliveryButton = ({ externalStyles, showingOutsideMenu = false }) => {
  const { merchantData } = useMerchant();
  const { isSandbox } = useMyStore();
  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();
  const { setIsCalendarHidden, isBurgerMenuVisible } = useClickStore();
  const { setSelectedStore } = useStoreAndChild();

  let history = useNavigate();

  const canCreateDelivery = canCreateDeliveriesService(merchantData?.capabilities, isSandbox);

  const redirectionService = (key) => {
    switch (key) {
      case "payment_method":
        history("/v1/settings/payments");
        break;
      case "account_overdrafted":
        history("/v1/settings/payments");
        break;
      default:
        break;
    }
  };

  const showRequiredAction = (capabilityObj) => {
    let pendingRequirment = "";
    let errorMessage = "Not authorized to create delivery";
    if (capabilityObj?.pendingRequirments?.includes("account_overdrafted")) {
      errorMessage = errorMessagesSerive["account_overdrafted"];
      pendingRequirment = "account_overdrafted";
    }
    if (capabilityObj?.pendingRequirments?.includes("payment_method")) {
      errorMessage = errorMessagesSerive["payment_method"];
      pendingRequirment = "payment_method";
    }

    toast.error(errorMessage);
    redirectionService(pendingRequirment);
  };
  const tooltipContainerForCreateDelivery = (
    <div className="flex flex-col gap-2">
      <span className={styles.tooltipHeading}>We’re improving how you create, manage, and automate deliveries on Burq. </span>
      <span className={styles.tooltipText}>
        You can now create “orders” on Burq instead of ”deliveries”. This makes it easier to manage your deliveries—when a
        delivery is rerouted or canceled, the new and old deliveries appear under a single order. To get started, create an order{" "}
        <span
          className={styles.tooltipHyperLink}
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/orders?create_order=true`;
          }}
        >
          here
        </span>
      </span>
    </div>
  );
  return (
    <>
      <button
        data-tip
        type="button"
        data-for={`goToCreatePage`}
        id="goToCreatePage"
        disabled={true}
        onClick={(e) => {
          e.preventDefault();
          setSelectedStore();
          if (isSandbox || canCreateDelivery.isEnabled) {
            history("/delivery");
          } else {
            setIsCalendarHidden(true);
            showRequiredAction(canCreateDelivery);
          }
        }}
        style={{
          width: isdesktop && "136px",
          backgroundColor: Colors.bgGrey,
          fontSize: isdesktop ? "12px" : "28px",
          fontWeight: "500",
          color: "white",
          height: isdesktop ? "34px" : "80px",
          textAlign: "center",
          borderRadius: "6px",
          padding: !isdesktop && "12px 22px",
          cursor: "pointer",
          ...externalStyles,
        }}
      >
        {isBurgerMenuVisible || showingOutsideMenu ? (
          <div className={`flex items-center justify-center w-[136px] gap-1 ${"text-[#92929C]"}`}>
            <CirclePlus size={16} strokeWidth={1.5} /> Create Delivery
          </div>
        ) : (
          <div className={`flex items-center justify-centerw-[136px] text-[#92929C] `}>
            <CirclePlus size={16} strokeWidth={1.5} className={!isdesktop && "mr-1.5"} />
            {!isdesktop && "Create Delivery"}
          </div>
        )}
      </button>
      <ToolTip
        backgroundColor="#fff"
        id={`goToCreatePage`}
        text={tooltipContainerForCreateDelivery}
        clickable
        className="!rounded-[6px] !border !border-[#d9d9d9] w-[587px] !p-4"
      />
    </>
  );
};

export default CreateDeliveryButton;
