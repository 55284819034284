import React, { Fragment } from "react";
import StatusBadgeReusable from "../shared/common/StatusBadgeReusable";
import styles from "./styles/listItem.module.css";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../../utils/helpers";
import classNames from "classnames";
import { badgesForDeliveryStatuses, iconHandler } from "../Admin/DeliveriesForAdmin/utils";
import momenttz from "moment-timezone";
import { classNameHandler } from "./utils";
import { incidentBadges } from "../DeliveryDetailsOnSidePopup/IncidentInfoAndReason/utils";
// import { numberWithCommas } from "../Analytics/utils";

const ListItem = ({ data, columns, isHome }) => {
  const navigate = useNavigate();

  const dateHandler = (dateString) => {
    const date = moment(dateString).format("YYYY");
    const currentYear = moment().format("YYYY");

    const formattedDate = moment(dateString).format("MMM D, YYYY");

    return date !== currentYear ? formattedDate : moment(dateString).format("MMMM D");
  };

  const rowHandler = (key) => {
    const format = "ddd, DD MMM YYYY, hh:mmA";
    if (isHome) {
      switch (key) {
        case "Delivery ID":
          return <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>{data.id}</td>;
        case "Drop-off Name":
          return (
            <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>
              {data.dropoffs[0].dropoff_name}
            </td>
          );
        case "Date Created":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              {moment(data?.created_at).format(format) === "Invalid date" ? "" : moment(data?.created_at).format(format)}
            </td>
          );
        case "Pick-up Address":
          return <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>{data.pickup_address}</td>;
        case "Status":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              <StatusBadgeReusable
                hideIcon={false}
                leftIcon={iconHandler(data?.status, true)}
                showDefault={true}
                badgesObject={badgesForDeliveryStatuses}
                defaultType="neutral"
                status={data?.status}
                externalClassName={styles.badge}
                extraIconClasses={styles.statusClass}
              />
            </td>
          );
        case "Drop-off phone number":
          return (
            <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>
              {data.dropoffs[0].dropoff_phone_number[0] === "+"
                ? data.dropoffs[0].dropoff_phone_number
                : "+" + data.dropoffs[0].dropoff_phone_number}
            </td>
          );

        case "Provider Name":
          return <td className={classNames(styles.tableRowText, "capitalize", classNameHandler(key))}>{data?.service}</td>;

        case "Scheduled Time":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              {data?.dropoff_at &&
                data.status !== "request" &&
                (moment(data.dropoff_at).format(format) === "Invalid date"
                  ? ""
                  : moment(data.dropoff_at).format(format) + ", " + momenttz.tz(momenttz.tz.guess()).format("z"))}
              {data?.pickup_at &&
                data.status !== "request" &&
                (moment(data.pickup_at).format(format) === "Invalid date"
                  ? ""
                  : moment(data.pickup_at).format(format) + ", " + momenttz.tz(momenttz.tz.guess()).format("z"))}
            </td>
          );

        case "Drop-off address":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              {data.dropoffs[0].dropoff_address ?? "Unassigned"}
            </td>
          );
        case "External delivery ID":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              {data.dropoffs[0].external_order_ref ?? "N/A"}
            </td>
          );

        case "Order Value":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              {data.order_value == 0 ? "$ 0" : data?.order_value ? "$" + (data?.order_value / 100).toFixed(2) : ""}
            </td>
          );

        default:
          break;
      }
    } else {
      switch (key) {
        case "Delivery ID":
          return <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>{data.id}</td>;
        case "Delivery & Fee Refunded":
          return (
            <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>
              ${data.delivery_fee_and_tip_refunded_amount ? numberWithCommas(data.delivery_fee_and_tip_refunded_amount) : "0.00"}
            </td>
          );
        case "Order Refunded":
          return (
            <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>
              ${data.order_value_refunded_amount ? numberWithCommas(data.order_value_refunded_amount) : "0.00"}
            </td>
          );

        case "Status":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              <StatusBadgeReusable
                externalClassName={styles.badgeExtraStyles}
                status={data.status}
                badgesObject={incidentBadges}
              />
            </td>
          );
        case "Date Created":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              {moment(data?.created_at).format(format) === "Invalid date" ? "" : moment(data?.created_at).format(format)}
            </td>
          );
        case "Provider Name":
          return <td className={classNames(styles.tableRowText, "capitalize", classNameHandler(key))}>{data?.service}</td>;
        case "Order Value":
          return (
            <td className={classNames(styles.tableRowText, classNameHandler(key))}>
              {data.order_value == 0 ? "$ 0" : data?.order_value ? "$" + (data?.order_value / 100).toFixed(2) : ""}
            </td>
          );
        case "Drop-off phone number":
          return (
            <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>
              {data.dropoff_phone_number[0] === "+" ? data.dropoff_phone_number : "+" + data.dropoff_phone_number}
            </td>
          );

        case "Drop-off Name":
          return <td className={classNames(styles.tableRowText, "truncate", classNameHandler(key))}>{data.dropoff_name}</td>;
        default:
          break;
      }
    }
  };

  return (
    <>
      <tr
        key={data.id}
        className={styles.tableRow}
        onClick={() => {
          navigate(`?deliveryId=${data.id}`);
        }}
      >
        {columns.map((column) => {
          if (column.isActive) {
            return rowHandler(column.header);
          } else {
            return <></>;
          }
        })}
      </tr>
    </>
  );
};

export default ListItem;
