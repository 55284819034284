import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import {
  useClickStore,
  useConnectedAccounts,
  useMerchant,
  userDataStore,
  useStoreAndChild,
  useTheme,
} from "../store";
import * as Yup from "yup";
import FormikNewTextInput from "../components/formikFields/FormikNewTextInput";
import PlacesAutocomplete from "react-places-autocomplete";
import CustomFormikPhoneNumber from "../components/CustomFormikPhoneNumber";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import ChooseTimezone from "./ChooseTimezone/ChooseTimezone";
import { BsExclamationCircle } from "react-icons/bs";
import CheckBoxComp from "../components/CheckBoxComp/CheckboxComp";
import { Loading } from "../assets/svgs/Svgs";
import { toast } from "react-toastify";

const WORKING_TIMES = {
  Sunday: { start: "", end: "" },
  Monday: { start: "", end: "" },
  Tuesday: { start: "", end: "" },
  Wednesday: { start: "", end: "" },
  Thursday: { start: "", end: "" },
  Friday: { start: "", end: "" },
  Saturday: { start: "", end: "" },
};

const IANATIMEZONES = [
  { front: "American Samoa", back: "Pacific/Pago_Pago" },
  { front: "Hawaii", back: "Pacific/Honolulu" },
  { front: "Alaska", back: "America/Juneau" },
  { front: "Pacific Time (US & Canada)", back: "America/Los_Angeles" },
  { front: "Tijuana", back: "America/Tijuana" },
  { front: "Mountain Time (US & Canada)", back: "America/Denver" },
  { front: "Arizona", back: "America/Phoenix" },
  { front: "Central Time (US & Canada)", back: "America/Chicago" },
  { front: "Eastern Time (US & Canada)", back: "America/New_York" },
  { front: "Indiana (East)", back: "America/Indiana/Indianapolis" },
  { front: "Atlantic Time (Canada)", back: "America/Halifax" },
  { front: "Puerto Rico", back: "America/Puerto_Rico" },
];

function CreateStoreModal({ connectedAccount }) {
  const { isdesktop, mdfontsize, smfontsize, xsfontsize } =
    useDesktopOrLaptop();
  const { Colors, themeChanger } = useTheme();
  const { setSelectedConnectedAccount, selectedConnectedAccount } =
    useConnectedAccounts();
  let {
    setIsCreateStoreModalOpen,
    postCreateStore,
    getAllStoresByUser,
    storeForEdit,
    setStoreForEdit,
    putUpdateStore,
    selectedStore,
    setSelectedStore,
    createAnotherStore,
    setCreateAnotherStore,
    isCreateStoreLoading,
    isUpdateStoreLoading,
  } = useStoreAndChild();
  const { setAllTimeVisible, setIsCalendarVisible } = useClickStore();
  const { isNewAccountCreated, setIsNewAccountCreated } =
    useConnectedAccounts();

  const { authInfo } = userDataStore();
  const { merchantData } = useMerchant();

  const [isClickedOnSubmit, setIsClickedOnSubmit] = useState(false);
  const [enableStorePaymentMethod, setEnableStorePaymentMethod] = useState(false);

  const [workingTimes, setWorkingTimes] = useState(
    storeForEdit?.working_hours ?? structuredClone(WORKING_TIMES)
  );

  const [selectedTimeZone, setSelectedTimeZone] = useState();

  const [selectedIndex, setSelectedIndex] = useState("");
  useEffect(() => {
    for (let i = 0; i < IANATIMEZONES.length; i++) {
      if (IANATIMEZONES[i].back === storeForEdit?.timezone) {
        setSelectedTimeZone(IANATIMEZONES[i]);
        setSelectedIndex(i);
        break;
      }
    }

    if (storeForEdit && storeForEdit.working_hours) {
      setWorkingTimes(storeForEdit.working_hours);
    } else {
      setWorkingTimes(structuredClone(WORKING_TIMES));
    }

    return () => {
      setIsClickedOnSubmit(false);
    };
  }, [storeForEdit]);

  const styles = {
    inputs: {
      fontFamily: "Poppins",
      borderRadius: "8px",
      fontSize: smfontsize,
      fontWeight: "400",
      color: Colors.fontDark,
      // backgroundColor: Colors.buttonBg,
    },
  };

  const formSchema = Yup.object().shape({
    storeName: Yup.string()
      .test("len", "Store name is required", (val) => val != " ")
      .required("Store name is required"),
    storePhoneNumber: Yup.string().required("Store phone number is required"),
    // .test("len", "Please input valid number", (val) => val?.length === 11),
    storeSuitApt: Yup.string(),
    storeAddress: Yup.string()
      .test("len", "Store address is required", (val) => val != " ")
      .required("Store address is required"),
    storeNotes: Yup.string(),
  });

  const initialValues = {
    storeName: storeForEdit?.name ?? "",
    storeSuitApt: storeForEdit?.unit_number ?? "",
    storePhoneNumber: storeForEdit?.phone_number ?? "",
    storeNotes: storeForEdit?.pickup_notes ?? "",
    storeAddress: storeForEdit?.address ?? "",
    merchant_store_id: storeForEdit?.merchant_store_id ?? "",
  };

  const workingHoursChecker = () => {
    for (const property in workingTimes) {
      if (workingTimes[property].start && workingTimes[property].end) {
        return true;
      }
    }
  };

  const submitHandler = async (vals, resetForm, setFieldValue) => {
    const accountId = selectedConnectedAccount
      ? selectedConnectedAccount?.id
      : authInfo.merchantAccountId;

    const payload = {
      name: vals.storeName,
      unit_number: vals.storeSuitApt,
      phone_number: vals.storePhoneNumber,
      address: vals.storeAddress,
      pickup_notes: vals.storeNotes,
      merchant_store_id: vals.merchant_store_id,
      timezone: selectedTimeZone?.back,
    };

    let error = false;
    if (storeForEdit) {
      await putUpdateStore(
        storeForEdit.id,
        vals.storeName,
        vals.storeSuitApt,
        vals.storePhoneNumber,
        vals.storeAddress,
        vals.storeNotes,
        vals.merchant_store_id,
        selectedTimeZone?.back,
        storeForEdit.settings
      ).then((response) => {
        if (!response) {
          error = true;
        } else {
          if (selectedStore) {
            setSelectedStore(response);
          }
          error = false;
        }
      });
    } else {
      const cb = async () => {
        await getAllStoresByUser(accountId, !!selectedConnectedAccount);
        toast.success("The store is created successfully");
        if (!createAnotherStore) {
          setIsCreateStoreModalOpen(false);
          setSelectedConnectedAccount();
          setIsNewAccountCreated(false);
        } else {
          resetForm();
          setFieldValue(`storePhoneNumber`, "+1");
          setIsClickedOnSubmit(false);
        }
      };
      payload.enable_store_payment_method = enableStorePaymentMethod;
      await postCreateStore(accountId, payload, cb);
    }

    if (!error && storeForEdit) {
      if (selectedConnectedAccount) {
        await getAllStoresByUser(selectedConnectedAccount.id, true);
      } else {
        await getAllStoresByUser(authInfo.merchantAccountId);
      }
      setStoreForEdit();
      setIsCreateStoreModalOpen(false);
      setSelectedConnectedAccount();
      setIsCalendarVisible(true);
    }
    setAllTimeVisible(true);
  };

  return (
    <div style={{ zIndex: 999 }} className="overflow-y-auto py-10">
      <div
        style={{ borderColor: Colors.borderPrimary, marginBottom: "20px" }}
        className="w-full px-8 pb-5 border-b"
      >
        <div className="w-full flex items-center justify-between">
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: mdfontsize,
              color: Colors?.fontDark,
              fontWeight: "500",
              width: "100%",
            }}
          >
            {storeForEdit ? (
              <div>
                <div>
                  Update Store:{" "}
                  <span style={{ color: Colors.fontGreyLight }}>
                    {storeForEdit.id}
                  </span>
                </div>
                {storeForEdit?.merchant_store_id && (
                  <div style={{ marginTop: "6px", fontSize: smfontsize }}>
                    Merchant Store ID:{" "}
                    <span style={{ color: Colors.fontGreyLight }}>
                      {storeForEdit.merchant_store_id}
                    </span>
                  </div>
                )}
              </div>
            ) : (
              "Create Store"
            )}
          </div>
          <button
            onClick={() => {
              setIsCreateStoreModalOpen(false);
              setAllTimeVisible(true);
              setStoreForEdit();
              setSelectedConnectedAccount();
              setIsCalendarVisible(true);
              setSelectedConnectedAccount(false);
              setIsNewAccountCreated(false);
            }}
            type="button"
            style={{
              color: Colors?.fontDark,
              fontSize: isdesktop ? "20px" : "36px",
            }}
            className="material-icons outline-none"
          >
            close
          </button>
        </div>
        {isNewAccountCreated && (
          <div style={{ fontSize: mdfontsize }}>
            New account has been created successfully. Add one or more stores
            below to start receiving deliveries.
          </div>
        )}
      </div>
      <Formik
        onSubmit={submitHandler}
        validationSchema={formSchema}
        enableReinitialize={true}
        initialValues={initialValues}
      >
        {({ values, errors, setFieldValue, resetForm }) => {
          return (
            <div className="w-full flex flex-col px-8">
              <div className="flex w-full items-center mb-8">
                <FormikNewTextInput
                  maxLength={100}
                  value={values.storeName}
                  name={`storeName`}
                  placeholder="Store Name *"
                  onBlur={() => {
                    setFieldValue("storeName", values.storeName.trim());
                  }}
                  isClickedOnContinue={isClickedOnSubmit}
                  emptyErr={!values.storeName ? "Store name is required" : ""}
                />
              </div>
              <div className="flex w-full items-start justify-center mb-5 flex-col">
                <FormikNewTextInput
                  maxLength={100}
                  value={values.merchant_store_id}
                  name={`merchant_store_id`}
                  placeholder="Merchant Store ID"
                  onBlur={() => {
                    setFieldValue(
                      "merchant_store_id",
                      values.merchant_store_id.trim()
                    );
                  }}
                  isClickedOnContinue={isClickedOnSubmit}
                />
                <div
                  className="flex"
                  style={{
                    paddingLeft: "10px",
                    marginTop: "4px",
                    color: Colors.fontGreyLight,
                    fontSize: xsfontsize,
                  }}
                >
                  <div style={{ marginTop: "4px", marginRight: "4px" }}>
                    <BsExclamationCircle />
                  </div>
                  If there is an ID for this store from your system, you can
                  optionally enter it here to keep track of the mapping
                </div>
              </div>
              <div className="flex mb-8">
                <PlacesAutocomplete
                  value={values.storeAddress}
                  onChange={(e) => {
                    setFieldValue(`storeAddress`, e);
                  }}
                  onSelect={(e) => {
                    setFieldValue(`storeAddress`, e);
                  }}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          padding: "0px 0px",
                          position: "relative",
                        }}
                      >
                        <FormikNewTextInput
                          {...getInputProps()}
                          smfontsize={smfontsize}
                          mdfontsize={mdfontsize}
                          name={`storeAddress`}
                          placeholder={"Search for address *"}
                          type="text"
                          isClickedOnContinue={isClickedOnSubmit}
                          emptyErr={
                            !values.storeName ? "Store address is required" : ""
                          }
                          onBlur={() => {
                            setFieldValue(
                              "storeAddress",
                              values.storeAddress.trim()
                            );
                          }}
                        />
                        <div className="w-full">
                          {loading ? (
                            <div
                              style={{
                                color: Colors.fontDark,
                                position: "absolute",
                              }}
                            >
                              ...loading
                            </div>
                          ) : null}

                          {suggestions.length > 0 && (
                            <div
                              className="rounded-lg"
                              style={{
                                position: "absolute",
                                backgroundColor: Colors.bgDarkSecondary,
                                width: "100%",
                                padding: "0px 10px 10px 10px",
                                zIndex: 999,
                              }}
                            >
                              {suggestions.map((sug, index) => {
                                const className = sug.active
                                  ? "bg-blue-400 text-blue-50"
                                  : "";
                                return (
                                  <div
                                    style={{
                                      fontSize: mdfontsize,
                                      color: Colors.fontDark,
                                      borderBottom: "1px solid lightgrey",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    id={index}
                                    key={sug.index}
                                    {...getSuggestionItemProps(sug, {
                                      className,
                                    })}
                                  >
                                    {sug.description}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  }}
                </PlacesAutocomplete>
              </div>
              <div
                style={{ height: isdesktop ? "74px" : "90px" }}
                className="flex w-full items-start justify-between "
              >
                <div style={{ width: "48%" }}>
                  <FormikNewTextInput
                    smfontsize={smfontsize}
                    mdfontsize={mdfontsize}
                    value={values.storeSuitApt}
                    name={`storeSuitApt`}
                    placeholder="Unit/Apt. #"
                    marginbottom="0px"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <CustomFormikPhoneNumber
                    isClickedOnSubmit={isClickedOnSubmit}
                    storeError
                    name={`storePhoneNumber`}
                    style={{
                      ...styles.inputs,
                      ...{
                        padding: "10px 40px",
                        width: "100%",
                        fontSize: smfontsize,
                        backgroundColor: Colors.borderPrimary,
                      },
                    }}
                    country={merchantData.country === "US" ? "us" : "ca"}
                    placeholder="+1 (###) ###-####"
                    type="tel"
                    format="+1 (###) ###-####"
                    onValueChange={(e) => {
                      setFieldValue(`storePhoneNumber`, e.formattedValue);
                    }}
                    value={values.storePhoneNumber}
                  />
                </div>
              </div>

              <div className="flex mb-8">
                <textarea
                  maxLength={"200"}
                  className={`${
                    themeChanger
                      ? "focus:border-gray-400"
                      : "focus:border-gray-300"
                  }  border border-transparent`}
                  value={values.storeNotes}
                  style={{
                    ...styles.inputs,
                    ...{
                      padding: "10px 20px",
                      width: "100%",
                      outline: "none",
                      backgroundColor: Colors.borderPrimary,
                      resize: "none",
                      maxHeight: "300px",
                    },
                  }}
                  onChange={(e) => {
                    setFieldValue("storeNotes", e.target.value);
                  }}
                  rows="3"
                  placeholder={"Add pickup instructions"}
                ></textarea>
              </div>

              {!storeForEdit && <div className="mb-8">
              <CheckBoxComp
                label="Use Separate Payment Method for Store"
                checked={enableStorePaymentMethod}
                onChecked={({ checked }) => {
                  setEnableStorePaymentMethod(checked);
                }}
                labelStyle={{ lineHeight: "30px", fontSize: mdfontsize }}
              />
                <div
                  className="flex"
                  style={{
                    paddingLeft: "10px",
                    marginTop: "4px",
                    color: Colors.fontGreyLight,
                    fontSize: xsfontsize,
                    top: "30px",
                    gap: "6px",
                    marginLeft: "40px",
                  }}
                >
                  <div style={{ marginTop: "4px", marginRight: "4px" }}>
                    <BsExclamationCircle />
                  </div>
                  This property cannot be changed after the store is created. If you check this box, you must select a payment method for the store. Otherwise you will not be able to create deliveries under the store. For customers receiving invoices, you will receive separate invoices for deliveries under this store. Any credit you receive from deliveries under this store can only be applied towards future deliveries under this store.
                </div>
              </div>}

              {/* <ChooseTimezone
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
                ianaTimezones={IANATIMEZONES}
                selectedTimeZone={selectedTimeZone}
                setSelectedTimeZone={setSelectedTimeZone}
                isClickedOnSubmit={isClickedOnSubmit}
              /> */}
              {/* <div
                style={{
                  fontFamily: "Poppins",
                  color: Colors.fontGreyLight,
                  fontSize: mdfontsize,
                }}
                className="w-full"
              >
                <div>Set Standard Hours</div>

                {Object.keys(workingTimes).map((day, index) => {
                  return (
                    <CreateStoreHours
                      workingTimes={workingTimes}
                      setWorkingTimes={setWorkingTimes}
                      key={index}
                      day={day}
                      selectedTimeZone={selectedTimeZone}
                    />
                  );
                })}
              </div> */}
              <div
                className="w-full flex items-center mt-3"
                style={{
                  justifyContent: !storeForEdit ? "space-between" : "flex-end",
                }}
              >
                {!storeForEdit && (
                  <CheckBoxComp
                    label="Create another store"
                    checked={createAnotherStore}
                    onChecked={({ checked }) => {
                      setCreateAnotherStore(checked);
                    }}
                    labelStyle={{ lineHeight: "30px" }}
                  />
                )}
                <div>
                  <button
                    onClick={() => {
                      setStoreForEdit();
                      setIsClickedOnSubmit(false);
                      setIsCreateStoreModalOpen(false);
                      setAllTimeVisible(true);
                      setIsCalendarVisible(true);
                      setSelectedConnectedAccount(false);
                      setIsNewAccountCreated(false);
                    }}
                    type="button"
                    style={{
                      backgroundColor: Colors.white,
                      fontFamily: "Poppins",
                      fontSize: isdesktop ? "16px" : "28px",
                      fontWeight: "500",
                      color: Colors.fontDark,
                      textAlign: "center",
                      borderRadius: "8px",
                      padding: "10px 30px",
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => {
                      setIsClickedOnSubmit(true);

                      if (
                        Object.keys(errors).length === 0 &&
                        // values.storePhoneNumber !== "+1" &&
                        values.storePhoneNumber !== "" &&
                        values.storeAddress !== "" &&
                        (values.storePhoneNumber.length === 17 ||
                          values.storePhoneNumber.length === 11 ||
                          values.storePhoneNumber.length === 12)
                        //   &&
                        // selectedTimeZone
                      ) {
                        submitHandler(values, resetForm, setFieldValue);
                      }
                    }}
                    type="button"
                    style={{
                      backgroundColor: Colors.bluePrimary,
                      fontFamily: "Poppins",
                      fontSize: isdesktop ? "16px" : "28px",
                      fontWeight: "500",
                      color: "#FAFAFB",
                      textAlign: "center",
                      borderRadius: "8px",
                      padding: "10px 30px",
                      marginLeft: "10px",
                    }}
                  >
                    {isUpdateStoreLoading || isCreateStoreLoading ? (
                      <div className="w-full flex justify-center">
                        {" "}
                        <Loading
                          color="#ECF0F1"
                          className="h-6 w-6 animate-spin"
                        />{" "}
                      </div>
                    ) : storeForEdit ? (
                      "Save"
                    ) : (
                      "Create"
                    )}
                  </button>
                </div>
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default CreateStoreModal;
