import React from "react";
import { useFlag, useFlagsStatus, useUnleashContext } from "@unleash/proxy-client-react";
import { Link, useNavigate } from "react-router-dom";
import { BurgerSvg } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

import { useClickStore, useMerchant, userDataStore } from "../../../store";

import logo from "../../../assets/png/dark.png";
import miniLogo from "../../../assets/png/BurqMiniLogo.png";

import { ROLES } from "../../AccountSettings/RolesSidebar/helpers";
import SidebarButton from "../SidebarButton";
import { SidebarDropdownButton } from "../SidebarDropdownButton";
import styles from "./styles.module.css";
import classNames from "classnames";
import { GiftBoxIcon, GiftIcon } from "./Svgs";
import Button from "../../shared/atoms/Button";
import CreateOrderButton from "../../shared/createOrderButton";
import BurqLogo from "../../../assets/svgs/branding/BurqLogo";
import JustBLogo from "../../../assets/svgs/branding/JustBLogo";
import FeedbackPopover from "../FeedbackPopover";

const MainSideBar = () => {
  const { merchantData } = useMerchant();
  const { userRole, isAdmin } = userDataStore();
  const isReferralsEnabled = useFlag("isReferralsEnabled");
  const newOrderManagement = useFlag("newOrderManagement");

  const enabledAnalytics = useFlag("analytics");
  const isFreeMonthEnabled = useFlag("freeMonth");
  const isUserRolesEnabled = useFlag("userRoles");
  const enabledProvidersManagement = useFlag("providersManagement");

  const isNewHomePageEnabled = useFlag("isNewHomePageEnabled");
  const analyticsAsHomeEnabled = useFlag("analyticsAsHomeEnabled");
  const isNewIncidentPageEnabled = useFlag("isNewIncidentPageEnabled");
  const isFeedbackPopoverEnabled = useFlag("isFeedbackPopoverEnabled");

  const { sidepanelactive, setSidepanelactive, isBurgerMenuVisible, setIsBurgerMenuVisible } = useClickStore();

  const { isdesktop } = useDesktopOrLaptop();
  let history = useNavigate();
  if (!isdesktop) return null;

  return (
    <div
      style={{
        width: isBurgerMenuVisible ? (!isdesktop ? "29vw" : "15vw") : "76px",
      }}
      className={classNames(styles.container, "flex flex-col justify-between trans flex-grow")}
    >
      {/* <SecondSidebar /> */}

      <div
        style={{
          width: "100%",
          position: !isdesktop && "absolute",
          height: "100%",
          overflowY: "auto",
        }}
        className={`z-20 flex-col items-center 2xl:visible md:flex noScroll`}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            paddingTop: isBurgerMenuVisible ? "14px" : "8px",
          }}
        >
          <div
            style={{
              padding: isBurgerMenuVisible ? "0px 16px 0px 9px" : "0px 12px 0px 16px",
              marginBottom: isBurgerMenuVisible ? "18px" : "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link
              id="homeId"
              to=""
              onClick={() => {
                // props.setSidepanelactive && props.setSidepanelactive("home");
              }}
            >
              <div
                style={
                  isBurgerMenuVisible
                    ? {
                        height: isdesktop ? "36px" : "100px",
                      }
                    : { width: "55px" }
                }
              >
                {isBurgerMenuVisible ? <BurqLogo width={120} height={50} /> : <JustBLogo width={40} height={40} />}
                {/* <img
                  style={isBurgerMenuVisible ? { objectFit: "cover", height: "100%" } : { objectFit: "cover", width: "100%" }}
                  src={isBurgerMenuVisible ? logo : miniLogo}
                  alt="Burq logo"
                /> */}
              </div>
            </Link>
            {isBurgerMenuVisible && (
              <button
                type="button"
                onClick={() => {
                  window.analytics.track("side menu is closed");
                  setIsBurgerMenuVisible(false);
                }}
              >
                <BurgerSvg />
              </button>
            )}
          </div>
          <div
            style={{
              padding: "0px 16px 0px 14px",
              marginBottom: "18px",
              marginTop: "30px",
            }}
          >
            <CreateOrderButton />
          </div>
          {isAdmin && (
            <SidebarButton
              id={"adminId"}
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="admin"
              title={isBurgerMenuVisible ? "Admin" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          )}
          {/* <SidebarButton
            id={"analyticsId"}
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active={"home"}
            title={isBurgerMenuVisible ? "Home" : ""}
            isBurgerMenuVisible={isBurgerMenuVisible}
          /> */}
          {analyticsAsHomeEnabled ? (
            <SidebarButton
              id={"analyticsId"}
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active={analyticsAsHomeEnabled ? "home" : "analytics"}
              title={isBurgerMenuVisible ? (analyticsAsHomeEnabled ? "Home" : "Analytics") : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          ) : (
            (isAdmin || enabledAnalytics) &&
            userRole === ROLES.ADMIN && (
              <SidebarButton
                id={"analyticsId"}
                setSidepanelactive={setSidepanelactive}
                setBurger={setIsBurgerMenuVisible}
                sidepanelactive={sidepanelactive}
                active={analyticsAsHomeEnabled ? "home" : "analytics"}
                title={isBurgerMenuVisible ? (analyticsAsHomeEnabled ? "Home" : "Analytics") : ""}
                isBurgerMenuVisible={isBurgerMenuVisible}
              />
            )
          )}

          <SidebarButton
            id="ordersButtonId"
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active="orders"
            title={isBurgerMenuVisible ? "Orders" : ""}
            isBurgerMenuVisible={isBurgerMenuVisible}
          />

          <SidebarButton
            id="homePageButtonId"
            setSidepanelactive={setSidepanelactive}
            setBurger={setIsBurgerMenuVisible}
            sidepanelactive={sidepanelactive}
            active={analyticsAsHomeEnabled ? "deliveries" : "home"}
            title={isBurgerMenuVisible ? (analyticsAsHomeEnabled ? "Deliveries" : "Home") : ""}
            isBurgerMenuVisible={isBurgerMenuVisible}
          />
          {isNewHomePageEnabled && (
            <SidebarButton
              id="newHomePageButtonId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="testPage"
              title={isBurgerMenuVisible ? "TestPage" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          )}
          {(isAdmin || isNewIncidentPageEnabled) && (
            <SidebarButton
              id="deliveryIncidentId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="deliveryIncident"
              title={isBurgerMenuVisible ? "Incidents" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          )}

          {merchantData?.account_type === "platform" && (
            <SidebarButton
              id="accountsId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="accounts"
              title={isBurgerMenuVisible ? "accounts" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          )}
          {isAdmin ? (
            <SidebarButton
              id="storesId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="stores"
              title={isBurgerMenuVisible ? "Stores" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          ) : (
            ((userRole !== ROLES.EMPLOEE && !isAdmin) || !isUserRolesEnabled) && (
              <SidebarButton
                id="storesId"
                setSidepanelactive={setSidepanelactive}
                setBurger={setIsBurgerMenuVisible}
                sidepanelactive={sidepanelactive}
                active="stores"
                title={isBurgerMenuVisible ? "Stores" : ""}
                isBurgerMenuVisible={isBurgerMenuVisible}
              />
            )
          )}

          {(enabledProvidersManagement || merchantData?.standardized_industry === "flowers") &&
            (userRole === ROLES.ADMIN || userRole === ROLES.STORE_MANAGER || !isUserRolesEnabled) &&
            merchantData?.account_type !== "connected" && (
              <SidebarButton
                isBurgerMenuVisible={isBurgerMenuVisible}
                id="deliveryProvidersId"
                setSidepanelactive={setSidepanelactive}
                setBurger={setIsBurgerMenuVisible}
                sidepanelactive={sidepanelactive}
                active="deliveryProviders"
                title={isBurgerMenuVisible ? "Providers" : ""}
              />
            )}

          {isAdmin ? (
            <SidebarButton
              id="developersId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="developers"
              title={isBurgerMenuVisible ? "Developers" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          ) : (
            merchantData?.account_type !== "connected" &&
            (userRole === "acct_admin" || !isUserRolesEnabled) && (
              <SidebarButton
                id="developersId"
                setSidepanelactive={setSidepanelactive}
                setBurger={setIsBurgerMenuVisible}
                sidepanelactive={sidepanelactive}
                active="developers"
                title={isBurgerMenuVisible ? "Developers" : ""}
                isBurgerMenuVisible={isBurgerMenuVisible}
              />
            )
          )}
          {isReferralsEnabled && (
            <SidebarButton
              id="referralsId"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="referrals"
              title={isBurgerMenuVisible ? "Referrals" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          )}
          {newOrderManagement && (
            <SidebarButton
              id="newOrderManagement"
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              active="newOrderManagement"
              title={isBurgerMenuVisible ? "Orders" : ""}
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          )}

          {isAdmin && merchantData?.settings?.is_self_setting_management_enabled && merchantData?.account_type !== "connected" ? (
            <SidebarDropdownButton
              setSidepanelactive={setSidepanelactive}
              setBurger={setIsBurgerMenuVisible}
              sidepanelactive={sidepanelactive}
              activeForDropdown="workflows"
              active="workflows"
              isBurgerMenuVisible={isBurgerMenuVisible}
            />
          ) : (
            merchantData?.settings?.is_self_setting_management_enabled &&
            merchantData?.account_type !== "connected" &&
            (userRole === ROLES.ADMIN || !isUserRolesEnabled) && (
              <SidebarDropdownButton
                setSidepanelactive={setSidepanelactive}
                setBurger={setIsBurgerMenuVisible}
                sidepanelactive={sidepanelactive}
                activeForDropdown="workflows"
                active="workflows"
                isBurgerMenuVisible={isBurgerMenuVisible}
              />
            )
          )}
        </div>
      </div>
      {/* {isBurgerMenuVisible && isFreeMonthEnabled && (
        <div className={styles.trialContainer}>
          <div className={styles.mainCont}>
            <div className={styles.title}>Avail a Free Month!</div>
            <div className={styles.desc}>Like our service? Help us grow by referring a business and earn rewards!</div>
            <Button
              id={"buttonForFreeMonthTrialTutorialTrigger"}
              text="Unlock Your Free Month"
              externalClassName={styles.button}
            />
            <div className={styles.giftCont}>
              <GiftIcon />
            </div>
          </div>
        </div>
      )} */}
      {/* {isBurgerMenuVisible && isReferralsEnabled && (
        <div className={styles.trialContainer}>
          <div className={styles.mainCont}>
            <GiftBoxIcon />
            <div className={styles.title}>Refer & Get $50!</div>
            <div className={styles.desc}>Refer a business and claim a gift card of up to $50!</div>
            <Button
              onClick={() => {
                history(`/v1/referrals`);
              }}
              id={"buttonForFreeMonthTrialTutorialTrigger"}
              text="Refer Now"
              externalClassName={styles.button}
            />
          </div>
        </div>
      )} */}

      {isBurgerMenuVisible && isFeedbackPopoverEnabled && (
        <div className={styles.trialContainer}>
          <div className="w-full h-full flex items-center justify-center">
            <FeedbackPopover />
          </div>
        </div>
      )}
    </div>
  );
};

export default MainSideBar;
