import React, { useState } from "react";
import { useMyStore, useTheme, useClickStore, useFilter, useMerchant, userDataStore, usePusherUpdates } from "../../../store";
import { Loading } from "../../../assets/svgs/Svgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

import DeliveriesList from "./DeliveriesList";
import DeliveriesListResponsive from "./DeliveriesListResponsive";
import PaymentMethodsZeroState from "../../shared/common/PaymentMethodsZeroState";
import { canCreateDeliveriesService } from "../../../utils/helpers";
import DeliveriesZeroState from "../../shared/common/DeliveriesZeroState";
import CustomModal from "../../CustomModal/CustomModal";
import CancelDeliveryRequest from "../../CancelDeliveryRequest";
import DeliveryDetailsResponsive from "./DeliveryDetailsResponsive";
import CreateOrderButton from "../../shared/createOrderButton";

const DeliveriesListSection = ({ scrollHandler, isInsideStore, activeIndex, setActiveIndex }) => {
  const { Colors } = useTheme();
  const { search } = useFilter();
  const { isBurgerMenuVisible, setIsBurgerMenuVisible } = useClickStore();
  const { isdesktop } = useDesktopOrLaptop();
  const { merchantData } = useMerchant();
  const [isCancelDeliveryModalVisible, setIsCancelDeliveryModalVisiblee] = useState(false);
  const [isDeliveryDetailsResponsiveOpen, setIsDeliveryDetailsResponsiveOpen] = useState(false);

  const { isAdmin } = userDataStore();
  const { deliveriesUpdates } = usePusherUpdates();

  const { deliveriesList, deliveriesLoading, totalDeliveries, isSandbox, deliveriesForAnalitycs } = useMyStore();

  const canCreateDelivery = canCreateDeliveriesService(merchantData?.capabilities, isSandbox);

  if (deliveriesForAnalitycs?.sumFee > 0 || deliveriesList?.length > 0 || search.trim() || totalDeliveries > 0) {
    return (
      <>
        {isDeliveryDetailsResponsiveOpen &&
          (isCancelDeliveryModalVisible ? (
            <CustomModal
              extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
              isOpen={isCancelDeliveryModalVisible}
              onClose={() => setIsCancelDeliveryModalVisiblee(false)}
            >
              <CancelDeliveryRequest setCancelVisible={setIsCancelDeliveryModalVisiblee} />
            </CustomModal>
          ) : (
            <DeliveryDetailsResponsive
              IsDetails={isDeliveryDetailsResponsiveOpen}
              setIsDetails={setIsDeliveryDetailsResponsiveOpen}
              isAdmin={isAdmin}
              setIsCancelDeliveryModalVisiblee={setIsCancelDeliveryModalVisiblee}
              socketMessage={deliveriesUpdates}
            />
          ))}
        {!isdesktop && !isDeliveryDetailsResponsiveOpen && (
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              width: "60%",
              zIndex: 99,
              left: "20%",
              right: "20%",
              justifyContent: "center",
              textAlign: "center",
              backgroundColor: Colors.bluePrimary,
              borderRadius: "8px",
            }}
            className="mr-0  rounded-lg flex"
          >
            <CreateOrderButton />
          </div>
        )}
        {deliveriesLoading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginTop: "100px",
              marginBottom: "100px",
            }}
          >
            <Loading color={Colors.bluePrimary} className="h-14 w-14 animate-spin" />
          </div>
        ) : (
          deliveriesList?.length > 0 &&
          deliveriesList?.map((item, index) => {
            return isdesktop ? (
              <DeliveriesList
                index={index}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                key={item.id}
                item={item}
                // socketMessage={message}
                socketMessage={deliveriesUpdates}
                scrollHandler={scrollHandler}
              />
            ) : (
              <DeliveriesListResponsive
                status={item.status}
                item={item}
                requestId={item.id}
                setIsDetails={setIsDeliveryDetailsResponsiveOpen}
                socketMessage={deliveriesUpdates}
                // socketMessage={message}
              />
            );
          })
        )}
      </>
    );
  } else if (!canCreateDelivery.isEnabled) {
    return (
      <div style={{ paddingTop: "10px" }} className="h-full w-full relative">
        <PaymentMethodsZeroState />
      </div>
    );
  } else {
    return (
      <div
        style={{
          paddingTop: "10px",
          backgroundColor: Colors.white,
          borderRadius: "12px",
          marginTop: "10px",
        }}
        className="h-full w-full relative"
      >
        <DeliveriesZeroState isInsideStore={isInsideStore} setBurger={setIsBurgerMenuVisible} burger={isBurgerMenuVisible} />
      </div>
    );
  }
};

export default DeliveriesListSection;
