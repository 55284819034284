import React from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useConnectedAccounts, useMerchant, useTheme } from "../../../store";
import CustomFormikPhoneNumber from "../../CustomFormikPhoneNumber";
import FormikTextInput from "../../FormikTextInput";

const FormFields = ({ values, setFieldValue }) => {
  const { Colors } = useTheme();
  const { smfontsize, mdfontsize } = useDesktopOrLaptop();
  const { accountForEditing } = useConnectedAccounts();
  const { merchantData } = useMerchant();

  return (
    <div
      style={{
        width: "100%",
        padding: "0px 24px",
      }}
    >
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: mdfontsize,
          marginBottom: "10px",
          fontWeight: "400",
          color: Colors.fontDark,
        }}
      >
        Full Name
      </div>
      <div style={{ height: "60px" }}>
        <FormikTextInput name="name" lable="name" placeholder="Full name" />
      </div>
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: mdfontsize,
          marginBottom: "10px",
          fontWeight: "400",
          color: Colors.fontDark,
        }}
      >
        Email
      </div>
      <div style={{ height: "60px" }}>
        <FormikTextInput
          // disabled={accountForEditing ? true : false}
          name="email"
          lable="email"
          placeholder="Business email"
        />
      </div>
      <div
        style={{
          fontFamily: "Poppins",
          fontSize: "16px",
          marginBottom: "10px",
          fontWeight: "400",
          color: Colors.fontDark,
        }}
      >
        Phone Number
      </div>
      <div style={{ height: "80px" }}>
        <CustomFormikPhoneNumber
          name="phone_number"
          style={{
            ...{
              padding: "10px 40px",
              width: "100%",
              fontSize: smfontsize,
              backgroundColor: Colors.white,
              marginBottom: "20px",
            },
          }}
          placeholder="+1 (###) ###-####"
          type="tel"
          format="+1 (###) ###-####"
          country={merchantData.country === "US" ? "us" : "ca"}
          onValueChange={(e) => {
            setFieldValue("phone_number", e.formattedValue);
          }}
          value={values.phone_number}
        />
      </div>
    </div>
  );
};

export default FormFields;
