import React from "react";
import styles from "./styles.module.css";
import {
  AccountsIcon,
  AdminIcon,
  AnalyticsIcon,
  DevelopersIcon,
  HomeIcon,
  OrdersIcon,
  ProvidersIcons,
  ReferralsIcon,
  ReviewIcon,
  StoresIcon,
  WorlflowsIcon,
} from "../Svgs";
import { FiPackage } from "react-icons/fi";

import classNames from "classnames";
import { useFilter, useStoreAndChild } from "../../../store";
import { useNavigate } from "react-router-dom";
import { MdOutlineAccountTree } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import { useFlag } from "@unleash/proxy-client-react";
import Lottie from "lottie-react";
import BadgeNew from "../../../assets/lottieFiles/BadgeNew.json";

const SidebarButton = ({
  id,
  setSidepanelactive,
  setBurger,
  sidepanelactive,
  active,
  title,
  isDropDown,
  noIcon,
  onClickHandler,
  isBurgerMenuVisible,
  isBadgeNew,
}) => {
  const { setFilterArray } = useFilter();
  let history = useNavigate();
  const isNewIncidentPageEnabled = useFlag("isNewIncidentPageEnabled");
  const isDeliveriesEnabled = useFlag("deliveries");
  const isNewReactAnalyticPageEnabled = useFlag("isNewReactAnalyticPageEnabled");
  const { setSelectedStore } = useStoreAndChild();

  const iconsHandler = (active, isActive) => {
    switch (active) {
      case "admin":
        return (
          <AdminIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "developers":
        return (
          <DevelopersIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "workflows":
        return (
          <WorlflowsIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isWorkflowActive,
            })}
          />
        );
      case "deliveryProviders":
        return (
          <ProvidersIcons
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "stores":
        return (
          <StoresIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "analytics":
        return (
          <AnalyticsIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "accounts":
        return (
          <MdOutlineAccountTree
            size={24}
            className={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "home":
        return (
          <HomeIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "orders":
      case "newOrderManagement":
        return (
          <OrdersIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );

      case "deliveryIncident":
        return (
          <ReviewIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "referrals":
        return (
          <ReferralsIcon
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      case "deliveries":
        return (
          <FiPackage
            size={"24px"}
            styles={classNames(styles.iconClasses, {
              [styles.active]: isActive,
            })}
          />
        );
      default:
        return null;
    }
  };

  const isActive = active !== "workflows" && sidepanelactive === active;
  const isWorkflowActive = active === "workflows" && sidepanelactive === active;
  return (
    <div
      onClick={() => {
        if (onClickHandler) {
          return onClickHandler(active);
        }
        // setSelectedStore();
        setFilterArray([]);
        setSidepanelactive(active);
        if (active === "orders") {
          return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/orders`);
        } else if (active === "deliveryIncident" && isNewIncidentPageEnabled) {
          return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/deliveryIncident`);
        } else if (active === "home" && isNewReactAnalyticPageEnabled) {
          return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/home`);
        } else if (active === "developers") {
          return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/developers`);
        } else if (active === "deliveries" && isDeliveriesEnabled) {
          return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/deliveries`);
        } else {
          history(`/v1/${active}`);
        }
      }}
      className={classNames(styles.container)}
    >
      <div
        className={classNames(styles.buttonContainer, {
          [styles.active]: isActive,
          [styles.activeWorkflow]: isWorkflowActive,
          [styles.closedSidebar]: !isBurgerMenuVisible,
        })}
      >
        <div className="flex items-center">
          {!noIcon && iconsHandler(active, isActive)}
          {isBurgerMenuVisible && <div className={styles.buttonTitle}>{title}</div>}
        </div>
        {isBadgeNew && <Lottie animationData={BadgeNew} loop={true} className="w-10 ml-2 shrink-0" />}
        {/* {isDropDown && (
          <div>
            <IoIosArrowDown
              size={18}
              className={classNames(styles.iconClasses, {
                [styles.active]: isActive,
                [styles.activeWorkflow]: isWorkflowActive,
              })}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};
export default SidebarButton;
