import { useField, useFormikContext } from "formik";
import React, { useState } from "react"; // import Colors from "../assets/Colors";
// import CommonInput from "./CommonInput";
import { useStoreAndChild, useTheme } from "../store";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMediaQuery } from "react-responsive";

function CustomFormikPhoneNumber(props) {
  const [field, meta] = useField(props.name);
  const { Colors, themeChanger } = useTheme();
  const { setFieldValue } = useFormikContext();
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const { selectedStore } = useStoreAndChild();

  const isdesktop = useMediaQuery({
    query: "(min-device-width: 1060px)",
  });

  return (
    <div
      style={{
        ...{
          marginBottom: props.marginbottom,
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: "100%",
        },
        ...props.containerStyles,
      }}
    >
      <div style={{ width: "100%", height: "100%" }}>
        {props.label && <label htmlFor={props.name}>{props.label}</label>}
        <PhoneInput
          disabled={props.disabled}
          prefix="+"
          countryCodeEditable={false}
          isValid={(value) => {
            if (value.length < 2) {
              setIsPhoneValid(true);
            } else if (
              value.match(
                /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/
              ) &&
              value.length === 11
            ) {
              setIsPhoneValid(true);
            } else {
              setIsPhoneValid(false);
            }
          }}
          autoFormat
          country={props.country || "us"}
          onlyCountries={["us", "ca"]}
          onChange={(value, country, e, formattedValue) => {
            setFieldValue(field.name, formattedValue);
          }}
          value={field.value}
          buttonStyle={{
            ...{
              borderWidth: "0px",
              backgroundColor: Colors.borderPrimary,
            },
            ...props.buttonStyle,
          }}
          disableDropdown
          inputStyle={{
            ...{
              fontFamily: "Poppins",
              borderRadius: "8px",
              fontSize: isdesktop ? "14px" : "18px",
              fontWeight: "400",
              color: Colors.fontDark,
              backgroundColor: Colors.borderPrimary,
              width: "100%",
              height: "100%",
              borderWidth: "1px",
              borderColor: Colors.borderPrimary,
            },
            ...props.style,
          }}
          placeholder="+1 (###) ###-####"
          style={{
            height: isdesktop ? "40px" : "54px",
          }}
          // {...props}
          // {...field}
        />
        {/* {meta.error && (meta.touched || props.isClicked) && (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: "12px",
              padding: "6px 10px 0px 12px",
            }}
          >
            {meta.error}
          </p>
        )} */}
        {!meta.error && !isPhoneValid && !props.storeError && (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: isdesktop ? "12px" : "18px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
            }}
          >
            Invalid Phone Number
          </p>
        )}
        {props.storeError && !meta.error && !isPhoneValid && (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: isdesktop ? "12px" : "18px",
              marginBottom: "0px",
              padding: "6px 0px 0px 12px",
              position: "absolute",
            }}
          >
            Invalid Phone Number
          </p>
        )}
        {props.isClickedOnSubmit &&
          (field.value === "" || field.value == "+1") && (
            <p
              style={{
                color: Colors.redDark,
                fontFamily: "Poppins",
                fontSize: isdesktop ? "12px" : "18px",
                marginBottom: "0px",
                padding: "6px 0px 0px 12px",
              position: "absolute",
              }}
            >
              Invalid Phone Number
            </p>
          )}
        {meta.error && meta.touched ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: isdesktop ? "12px" : "18px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
            }}
          >
            {meta.error}
          </p>
        ) : props.isClickedOnContinue && meta.error ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: isdesktop ? "12px" : "18px",
              padding: "6px 10px 0px 12px",
                position: "absolute",
            }}
          >
            {meta.error}
          </p>
        ) : props.error && meta.error ? (
          <p
            style={{
              color: Colors.redDark,
              fontFamily: "Poppins",
              fontSize: isdesktop ? "12px" : "18px",
              padding: "6px 10px 0px 12px",
              position: "absolute",
            }}
          >
            {meta.error}
          </p>
        ) : null}
      </div>
      {props.nonreq && (
        <div
          style={{
            padding: "4px 8px",
            backgroundColor: Colors.bgGrey,
            borderRadius: "8px",
            marginLeft: "10px",
          }}
        >
          optional
        </div>
      )}
    </div>
  );
}

export default CustomFormikPhoneNumber;

// import { useField } from "formik";
// import React from "react";
// // import Colors from "../assets/Colors";
// // import CommonInput from "./CommonInput";
// import { useTheme } from "../store";

// function CustomFormikPhoneNumber(props) {
//   const [field, meta] = useField(props.name);
//   const { Colors } = useTheme();

//   return (
//     <div
//       style={{
//         marginBottom: props.marginbottom,
//         display: "flex",
//         alignItems: "center",
//         width: "100%",
//       }}
//     >
//       <div style={{ width: "100%" }}>
//         {props.label && <label htmlFor={props.name}>{props.label}</label>}
//         <NumberFormat
//           style={{
//             borderWidth: "1px",
//             borderRadius: "10px",
//             fontSize: "14px",
//             fontWeight: "400",
//             color: Colors.fontDark,
//             padding: "8px 12px",
//             outline: "none",
//             width: "100%",
//             fontFamily: "Poppins",
//           }}
//           {...field}
//           {...props}
//         />

//         {meta.error && meta.touched ? (
//           <p
//             style={{
//               color: Colors.redDark,
//               fontFamily: "Poppins",
//               fontSize: "12px",
//               padding: "6px 10px 0px 12px",
//             }}
//           >
//             {meta.error}
//           </p>
//         ) : props.isClickedOnContinue && meta.error ? (
//           <p
//             style={{
//               color: Colors.redDark,
//               fontFamily: "Poppins",
//               fontSize: "12px",
//               padding: "6px 10px 0px 12px",
//             }}
//           >
//             {meta.error}
//           </p>
//         ) : null}
//       </div>
//       {props.nonreq && (
//         <div
//           style={{
//             padding: "4px 8px",
//             backgroundColor: Colors.bgGrey,
//             borderRadius: "8px",
//             marginLeft: "10px",
//           }}
//         >
//           optional
//         </div>
//       )}
//     </div>
//   );
// }

// export default CustomFormikPhoneNumber;
