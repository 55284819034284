import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import { BsInfo } from "react-icons/bs";
import { useFlag } from "@unleash/proxy-client-react";
import HeaderTabs from "../../shared/common/HeaderTabs";
import BYOPKTab from "./BYOPK";
import CommonToggle from "../../CommonToggle";
import { useMyStore, useProvidersManagementStore, useTheme, userDataStore, useMerchant } from "../../../store";
import ToolTip from "../../Tooltip/Tooltip";
import ProviderDetails from "./ProviderDetails";
import RequestAccessTab from "./RequestAccess";
import styles from "./styles/styles.module.css";
import RequestContactTab from "./RequestContact";
import RequestProviderTab from "./RequestProvider";

export const TABS = {
  PROVIDER: "Provider",
  BYOPK: "BYOPK",
  REQUEST_ACCESS: "Request Access",
  CONTACT_REQUEST: "Contact Request",
  REQUEST_PROVIDER: "Request Provider",
};

const ProviderIntegrationDetails = ({ provider, onClose }) => {
  const [activeTab, setActiveTab] = useState(TABS.PROVIDER);
  const {
    updateAccountProviderSettingHandler,
    getMerchantProvidersHandler,
    defaultActiveTab,
    setDefaultActiveTab,
    isMissingProviderRequestFormOpen,
  } = useProvidersManagementStore();
  const { isSandbox } = useMyStore();
  const { authInfo } = userDataStore();
  const { merchantData } = useMerchant();

  const enabledBYOPK = useFlag("BYOPK");

  const { Colors } = useTheme();
  const aps = provider?.account_provider_setting;

  useEffect(() => {
    if (defaultActiveTab) {
      setActiveTab(defaultActiveTab);
    }
    return () => {
      setDefaultActiveTab("");
    };
  }, [defaultActiveTab]);

  const tabs = [
    { label: TABS.PROVIDER, value: TABS.PROVIDER, isHidden: !provider },
    {
      label: TABS.BYOPK,
      value: TABS.BYOPK,
      isHidden:
        !provider ||
        !enabledBYOPK ||
        !provider?.accept_own_provider_keys ||
        !(merchantData?.account_type === "standard" || merchantData?.account_type === "platform"),
    },
    {
      label: TABS.REQUEST_ACCESS,
      value: TABS.REQUEST_ACCESS,
      isHidden: !provider || provider?.access_request_status || !provider?.access_required,
    },
    {
      label: TABS.CONTACT_REQUEST,
      value: TABS.CONTACT_REQUEST,
      isHidden: !provider || provider?.access_required,
    },
    {
      label: TABS.REQUEST_PROVIDER,
      value: TABS.REQUEST_PROVIDER,
      isHidden: !!provider,
    },
  ];

  const accountProviderSettingUpdateHandler = async () => {
    const initial_payload = {
      is_merchant_preferred: aps?.is_merchant_preferred,
      is_test_mode_enabled: aps?.is_test_mode_enabled,
      is_live_mode_enabled: aps?.is_live_mode_enabled,
    };

    const prop = isSandbox ? "is_test_mode_enabled" : "is_live_mode_enabled";
    const res = await updateAccountProviderSettingHandler(aps?.id, {
      ...initial_payload,
      [prop]: !aps?.[prop],
    });
    if (!(res instanceof Error)) {
      onClose();
      getMerchantProvidersHandler(authInfo.merchantAccountId, isSandbox);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case TABS.BYOPK:
        return <BYOPKTab data={provider} />;
      case TABS.REQUEST_ACCESS:
        return <RequestAccessTab />;
      case TABS.CONTACT_REQUEST:
        return <RequestContactTab data={provider} />;
      case TABS.REQUEST_PROVIDER:
        return <RequestProviderTab />;
      default:
        return <ProviderDetails data={provider} />;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.drawerHeader}>
        <div className={styles.drawerTitleSection}>
          {isMissingProviderRequestFormOpen ? "Request Provider" : provider?.name}
          {aps && (
            <>
              <CommonToggle
                id="headerToggle"
                smsHandler={() => accountProviderSettingUpdateHandler()}
                checked={provider?.is_enabled}
                translateX={15}
                circleStyle={{
                  width: "16px",
                  height: "16px",
                  backgroundColor: "white",
                }}
                style={{
                  marginLeft: "10px",
                  width: "37px",
                  height: "20px",
                  padding: "3px",
                  backgroundColor: provider?.is_enabled ? Colors.bluePrimary : "#979797",
                }}
              />
              <div data-tip data-for={`enableProviderToggleButtonInfo`} className="cursor-pointer relative normal-case">
                <BsInfo size={30} color={Colors.fontGreyLight} />
                <ToolTip
                  id={`enableProviderToggleButtonInfo`}
                  disable={false}
                  text="Merchant can switch on and off the provider."
                />
              </div>
            </>
          )}
        </div>
        <CgClose className={styles.closeIcon} size={30} color={Colors.fontGreyLight} onClick={onClose} />
      </div>
      <div className={styles.body}>
        <HeaderTabs
          extraButtonClasses={styles.tabButton}
          extraBottomLineClasses={styles.tabLine}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          tabs={tabs}
        />
        {renderTabContent()}
      </div>
    </div>
  );
};

export default ProviderIntegrationDetails;
