import React, { useEffect, useRef } from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import AnalyticsCardsResponsove from "./AnalyticsCardsResponsove";
import AnalyticsMiniCardsNew from "./AnalyticsMiniCardsNew";
import DeliveriesAnalytics from "../DeliveriesAnalytics";
import { useMyStore, usePaymentStore, useTheme, useStoreAndChild, useFilter, userDataStore } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import axios from "axios";
import MissingPaymentMethodAlert from "../shared/common/MissingPaymentMethodAlert";
import SuspendedAccountAlert from "../shared/common/SuspendedAccountAlert";

const HomePage = () => {
  const { Colors } = useTheme();

  const { isdesktop } = useDesktopOrLaptop();
  const { selectedStore } = useStoreAndChild();
  const { filterStore, startDate, setStartDate, search, setSearch, setFilterStore, filterArray } = useFilter();

  const { deliveriesForAnalitycs, isSandbox, setPageCount, deliveries, pageCount } = useMyStore();
  const { authInfo } = userDataStore();
  const { loadCardList } = usePaymentStore();

  const TITLES = [
    {
      title: "Deliveries",
      percent: 2.5,
      deliveriesForAnalityc: deliveriesForAnalitycs?.total,
    },
    {
      title: "Completed",
      percent: 2.5,
      deliveriesForAnalityc: deliveriesForAnalitycs?.completed,
    },
    {
      title: "Canceled",
      percent: 2.5,
      deliveriesForAnalityc: deliveriesForAnalitycs?.canceled,
    },
  ];
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const scrollRef = useRef(null);

  const scrollHandler = (top) => {
    scrollRef.current?.scrollTo({
      top: top,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.analytics.page();
  }, []);

  useEffect(() => {
    loadCardList(authInfo?.merchantAccountId);
  }, [authInfo]);

  useEffect(() => {
    deliveries("", 0, "", selectedStore ? selectedStore.id : filterStore?.id, filterArray, source);
    setPageCount(0);
    setSearch("");
    setStartDate("");
    return () => {
      source.cancel();
    };
  }, [isSandbox, selectedStore]);

  useEffect(() => {
    deliveries(
      search,
      pageCount,
      startDate,
      selectedStore ? selectedStore.id : filterStore?.id ? filterStore?.id : "",
      filterArray,
      source
    );
    return () => {
      setFilterStore();
      source.cancel();
    };
  }, [pageCount]);

  return (
    <div
      ref={scrollRef}
      style={{
        padding: "0px 24px 100px 24px",
        backgroundColor: Colors.white,
        height: isdesktop ? `calc(100% - 70px)` : `calc(100% - 200px)`,
      }}
      className="md:w-full flex flex-col  shadow-sm overflow-y-auto   "
    >
      <SuspendedAccountAlert />
      <MissingPaymentMethodAlert />

      {isdesktop ? (
        <div
          style={{
            margin: "30px 0px 0px 0px",
            flexDirection: isdesktop ? "row" : "column",
            backgroundColor: Colors.white,
            borderRadius: "10px",
          }}
          className="items-center flex justify-between w-full flex-wrap shadow-md"
        >
          <div
            style={{
              width: isdesktop ? "33%" : "100%",
              flexDirection: "row",
            }}
            className="flex justify-between "
          >
            <AnalyticsMiniCardsNew title="Deliveries" sum={deliveriesForAnalitycs?.total} percent={2.5} />
          </div>
          <div
            style={{
              width: isdesktop ? "33%" : "100%",
              marginTop: isdesktop ? "0px" : "30px",
            }}
            className="flex flex-row   justify-between"
          >
            <AnalyticsMiniCardsNew title="Completed" sum={deliveriesForAnalitycs?.completed} percent={0.5} />
          </div>
          <div
            style={{
              width: isdesktop ? "33%" : "100%",
              marginTop: isdesktop ? "0px" : "30px",
            }}
            className="flex flex-row   justify-between"
          >
            <AnalyticsMiniCardsNew title="Canceled" sum={deliveriesForAnalitycs?.canceled} percent={-1.5} />
          </div>
        </div>
      ) : (
        <ScrollMenu
          horizontal={true}
          scrollContainerClassName="pb-10"
          style={{
            width: "100%",
            flexDirection: "row",
            display: "flex",
            scrollBehavior: "smooth",
          }}
        >
          {TITLES.map((item, index) => (
            <AnalyticsCardsResponsove
              title={item.title}
              itemId={index}
              key={index}
              percent={item.percent}
              sum={item.deliveriesForAnalityc}
              usd={index === 3 ? true : false}
            />
          ))}
        </ScrollMenu>
      )}

      <div className={`lg:flex lg:flex-row  flex flex-col mt-5`}>
        <DeliveriesAnalytics scrollHandler={scrollHandler} />
      </div>
    </div>
  );
};

export default HomePage;
