import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useUnleashContext, useFlag, useFlagsStatus } from "@unleash/proxy-client-react";
import Header from "../Header";
import HeaderResponsive from "../Header/HeaderResponsive";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { userDataStore, useMyStore, useClickStore, useTheme, useMerchant, useDelInfoOnSidePopup, useWorkflow } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import CustomModal from "../CustomModal/CustomModal";
import MobileNavigation from "../MobileNavigation";
import MainSideBar from "../Sidebar/MainSideBar";
import AddIndustryModal from "../../modals/AddIndustryModal/AddIndustryModal";
import CustomFullScreenModal from "../CustomModal/CustomFullScreenModal";

import { useSearchParams } from "react-router-dom";
import { ROLES } from "../AccountSettings/RolesSidebar/helpers";
import DeliverySideDrawer from "../DeliveryDetailsOnSidePopup/DeliverySideDrawer";
import { toast } from "react-toastify";
import BusinessForm from "../BusinessForm";

function V1Layout() {
  const updateContext = useUnleashContext();
  const isUserRolesEnabled = useFlag("userRoles");
  const enabledProvidersManagement = useFlag("providersManagement");

  const { setActiveWorkflow } = useWorkflow();

  const { getMerchantData, isIndustriesModalVisible, merchantData } = useMerchant();
  const { setDeliveryIdFromQueryParam } = useDelInfoOnSidePopup();
  const {
    // sidepanelactive,
    setSidepanelactive,
    isBurgerMenuVisible,
    setIsBurgerMenuVisible,
    setAllTimeVisible,
    setIsCalendarVisible,
  } = useClickStore();
  const { userId } = useMyStore();
  const { loginAuth, authInfo, userRole, isAdmin } = userDataStore();
  const [searchParams, setSearchParams] = useSearchParams();

  const sidePopupHandlerOnLoad = () => {
    const clientSecret = new URLSearchParams(window.location.search).get("deliveryId");
    setDeliveryIdFromQueryParam(clientSecret);
    if (clientSecret) {
      setAllTimeVisible(false);
      setIsCalendarVisible(false);
    }
  };

  const { Colors } = useTheme();
  const { isdesktop } = useDesktopOrLaptop();

  const enabledMultiStop = useFlag("multistop");
  const enabledAnalytics = useFlag("analytics");
  const isReferralsEnabled = useFlag("isReferralsEnabled");
  const isNewHomePageEnabled = useFlag("isNewHomePageEnabled");
  const newOrderManagement = useFlag("newOrderManagement");
  const analyticsAsHomeEnabled = useFlag("analyticsAsHomeEnabled");
  const isNewIncidentPageEnabled = useFlag("isNewIncidentPageEnabled");
  const isNewReactAnalyticPageEnabled = useFlag("isNewReactAnalyticPageEnabled");

  const homeRedirectHandler = () => {
    if (isNewReactAnalyticPageEnabled) {
      window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/home`;
    } else {
      history("/v1/home");
    }
  };

  let history = useNavigate();

  const [isMerchantCreated, setIsMerchantCreated] = useState(false);
  const [isAuthInfoLoading, setIsAuthInfoLoading] = useState(false);

  const [IsModal, setIsModal] = useState(false);

  const loginHandler = async () => {
    setIsAuthInfoLoading(true);
    if (!userId) {
      await loginAuth().then((res) => {
        if (res.data?.user?.merchantAccountId) {
          setIsMerchantCreated(true);
          // getMerchantData(res.data.user.merchantAccountId).then((response) => {
          //   if (response?.id) {
          //     updateContext({ userId: response.id });
          //   }
          //   if (!response?.standardized_industry) {
          //     if (response.account_type !== "connected") history("/v1/home");
          //   }
          // });
          setIsAuthInfoLoading(false);
        } else {
          // if (res?.data) {
          //   history("/businessForm");
          // }
          setIsAuthInfoLoading(false);
          setIsMerchantCreated(false);
        }
      });
      window.gtag("event", "users", {
        userEmails: authInfo?.email,
      });
    }
  };
  const { flagsReady } = useFlagsStatus();

  useEffect(() => {
    if (isdesktop) {
      setIsBurgerMenuVisible(true);
    } else if (!isdesktop) {
      setIsBurgerMenuVisible(false);
    }
    if (!authInfo) {
      loginHandler();
    }
    if (authInfo) {
      // updateContext({ userId: authInfo?.email });
    }
  }, [isdesktop]);

  // useEffect(() => {
  //   if (authInfo?.merchantAccountId && !isMerchantCreated) {
  //     window.gtag("set", "userId", authInfo?.email);
  //     setIsMerchantCreated(true);
  //   }
  // }, [authInfo]);

  useEffect(() => {
    if (authInfo?.merchantAccountId && !isMerchantCreated) {
      setIsMerchantCreated(true);
      window.gtag("set", "userId", authInfo?.email);
    }
    //for backward compatibility
    if (window.location.pathname === "/v1/settings") {
      history("/v1/settings/payments");
    } else if (window.location.pathname === "/v1/settingsbilling") {
      window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/settings/billing`;
    } else if (window.location.pathname === "/v1/settings/notifications") {
      window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/settings/notifications`;
    } else if (window.location.pathname === "/v1/settings/details") {
      window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/settings/details`;
    }

    if (!merchantData) {
      return;
    }

    const splitedPath = window.location.pathname.split("/");
    if (splitedPath[2] === "admin" && isAdmin) {
      setSidepanelactive("admin");
    } else if (splitedPath[2] === "settings") {
      setSidepanelactive("settings");
    } else if (splitedPath[2] === "home") {
      setSidepanelactive("home");
      // if (isNewReactAnalyticPageEnabled) {
      //   window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/home`;
      // } else {
      //   history("/v1/home");
      // }
    } else if (splitedPath[2] === "deliveryProviders") {
      if (
        (enabledProvidersManagement || merchantData?.standardized_industry === "flowers") &&
        (userRole === ROLES.ADMIN || userRole === ROLES.STORE_MANAGER || !isUserRolesEnabled) &&
        merchantData?.account_type !== "connected"
      ) {
        setSidepanelactive("deliveryProviders");
      } else {
        toast.error("You do not have access to this page. Please contact customer support for support.");
        // history("/v1/home");
        homeRedirectHandler();
      }
    } else if (splitedPath[2] === "deliveryIncident") {
      if (isNewIncidentPageEnabled) {
        window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/deliveryIncident`;
      } else {
        if (isAdmin) {
          setSidepanelactive("deliveryIncident");
        } else {
          toast.error("You do not have access to this page. Please contact customer support for support.");
          // history("/v1/home");
          homeRedirectHandler();
        }
      }
    } else if (splitedPath[2] === "developers" && merchantData?.account_type !== "connected") {
      if (isAdmin || userRole === ROLES.ADMIN || !isUserRolesEnabled) {
        // setSidepanelactive("developers");
        window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/developers`;
      } else {
        toast.error("You do not have access to this page. Please contact customer support for support.");
        // history("/v1/home");
        homeRedirectHandler();
      }
    } else if (
      splitedPath[2] === "workflows" &&
      merchantData?.settings?.is_self_setting_management_enabled &&
      merchantData?.account_type !== "connected"
    ) {
      if (isAdmin || userRole === ROLES.ADMIN || !isUserRolesEnabled) {
        setSidepanelactive("workflows");
        if (splitedPath[3] === "quote") {
          setActiveWorkflow("quote");
        } else if (splitedPath[3] === "branding") {
          setActiveWorkflow("branding");
        } else if (splitedPath[3] === "order-workflow") {
          setActiveWorkflow("order-workflow");
        } else {
          setActiveWorkflow("sms");
        }
      } else {
        toast.error("You do not have access to this page. Please contact customer support for support.");
        // history("/v1/home");
        homeRedirectHandler();
      }
    } else if (splitedPath[2] === "orders") {
      window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/orders`;
      // setSidepanelactive("orders");
    } else if (splitedPath[2] === "accounts" && merchantData?.account_type === "platform") {
      if (isAdmin || userRole === ROLES.ADMIN || !isUserRolesEnabled) {
        setSidepanelactive("accounts");
      } else {
        // history("/v1/home");
        homeRedirectHandler();
      }
    } else if (splitedPath[2] === "stores") {
      if (isAdmin || userRole !== ROLES.EMPLOEE || !isUserRolesEnabled) {
        setSidepanelactive("accounts");
      } else {
        // history("/v1/home");
        homeRedirectHandler();
      }
      setSidepanelactive("stores");
    } else if (splitedPath[2] === "testPage") {
      if (isNewHomePageEnabled) {
        setSidepanelactive("testPage");
      } else {
        // history("/v1/home");
        homeRedirectHandler();
      }
    } else if (splitedPath[2] === "trips" && enabledMultiStop) {
      setSidepanelactive("trips");
    } else if (splitedPath[2] === "analytics" && (isAdmin || enabledAnalytics || analyticsAsHomeEnabled)) {
      if (analyticsAsHomeEnabled) {
        // history("/v1/home");
        homeRedirectHandler();
      } else {
        setSidepanelactive("analytics");
      }
    } else if (splitedPath[2] === "referrals") {
      if (isReferralsEnabled) {
        setSidepanelactive("referrals");
      } else {
        // history("/v1/home");
        homeRedirectHandler();
      }
    } else if (splitedPath[2] === "orderManagement") {
      if (newOrderManagement) {
        setSidepanelactive("orderManagement");
      } else {
        // history("/v1/home");
        homeRedirectHandler();
      }
    } else if (splitedPath[2] === "deliveries") {
      setSidepanelactive("deliveries");
    } else {
      // history("/v1/home");
      homeRedirectHandler();
    }
  }, [isAdmin, window.location.pathname, merchantData, enabledMultiStop, flagsReady]);

  useEffect(() => {
    window.gtag("event", "page_view", {
      page_location: window.location.href,
      page_path: window.location.pathname,
      page_title: window.location.pathname,
    });
    sidePopupHandlerOnLoad();
  }, [window.location.href]);

  if (isAuthInfoLoading) return null;
  if (!merchantData) {
    return <></>;
  }

  // if (isMerchantCreated) {
  return (
    <div className="h-screen font-sans w-screen flex flex-row overflow-hidden  ">
      <CustomModal
        extraPanelStyles={{ maxWidth: isdesktop ? "50%" : "80%" }}
        isOpen={isIndustriesModalVisible}
        onClose={() => {
          // setIsIndustriesModalVisible(false);
        }}
      >
        <AddIndustryModal />
      </CustomModal>
      <MainSideBar />
      <div
        className="trans"
        style={{
          width: isdesktop ? (isBurgerMenuVisible ? "85vw" : `calc(100vw - 76px)`) : "100vw",
          backgroundColor: Colors.white,
          position: "relative",
          height: "100%",
        }}
      >
        {/* <Header setSidepanelactive={setSidepanelactive} /> */}
        {isdesktop ? (
          <Header setSidepanelactive={setSidepanelactive} />
        ) : (
          <HeaderResponsive
            setSidepanelactive={setSidepanelactive}
            setIsBurgerMenuVisible={setIsBurgerMenuVisible}
            isBurgerMenuVisible={isBurgerMenuVisible}
            IsModal={IsModal}
            setIsModal={setIsModal}
          />
        )}

        <div
          style={{
            width: "100%",
            backgroundColor: Colors.white,
          }}
          className=" justify-center items-start h-full "
        >
          <Outlet />
        </div>
      </div>
      <CustomFullScreenModal
        isOpen={IsModal}
        extraStyles={{
          backgroundColor: Colors.white,
        }}
        onClose={() => {
          setIsModal((prev) => !prev);
        }}
      >
        <MobileNavigation IsModal={IsModal} setIsModal={setIsModal} isdesktop={isdesktop} isAdmin={isAdmin} />
      </CustomFullScreenModal>
    </div>
  );
  // } else if (authInfo && !isMerchantCreated) {
  //   return <BusinessForm loginHandler={loginHandler} />;
  // } else {
  //   return null;
  // }
}

export default V1Layout;
