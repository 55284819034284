import React, { useEffect, useState, useRef } from "react";
import posthog from "posthog-js";
import Button from "../shared/atoms/Button";
import { FeedbackFormIllustration, FeedbackIcon } from "./Svgs";
import { toast } from "react-toastify";

const FeedbackPopover = () => {
  const feedbackSurveyID = process.env.REACT_APP_POSTHOG_FEEDBACK_SURVEY_ID;
  const [feedback, setFeedback] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const popoverRef = useRef(null);
  const buttonRef = useRef(null);

  const handleClose = (e) => {
    e.preventDefault();
    setIsVisible(false);
    setFeedback("");
  };

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    try {
      posthog.capture("survey sent", {
        $survey_id: feedbackSurveyID,
        $survey_response: feedback,
      });
      setIsVisible(false);
      setFeedback("");
      toast.success("Feedback Submitted Successfully");
    } catch (error) {
      setIsVisible(false);
      setFeedback("");
      toast.success("Feedback Submitted Successfully");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target) // Ignore clicks on the button
      ) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible]);

  return (
    <>
      <button
        ref={buttonRef}
        className="flex items-center p-2.5 text-blue-600 text-[14px] font-medium leading-[20px] underline"
        onClick={() => setIsVisible((prev) => !prev)}
      >
        <FeedbackIcon className="fill-blue-600" />
        <span className="ml-1">Help us improve</span>
      </button>

      {isVisible && (
        <div
          className="mb-4 bg-white md:left-[16%] xl:left-[20%] 2xl:left-[25%]"
          ref={popoverRef}
          style={{
            width: 508,
            boxShadow: "0px 2px 32px 0px #00000033",
            position: "absolute",
            bottom: 50,
            width: 508,
            borderRadius: 10,
            padding: 24,
            zIndex: 1000,
          }}
        >
          <div>
            <div className="flex justify-between items-center">
              <h3 className="text-[18px] font-semibold text-gray-1200">Help us improve</h3>
            </div>
            <form className="mr-2">
              <div className="flex">
                <p className="text-[14px] text-gray-800 w-[350px] leading-[21px] font-normal">
                  Have feedback on something? Share your thoughts—we're all ears and always looking to improve.
                </p>
                <span className="right-[50px] w-[114.61px] h-[95.14px] translate-x-[50px]">
                  <FeedbackFormIllustration />
                </span>
              </div>

              <p className="text-[16px] font-medium text-gray-1100" style={{ marginBottom: 8 }}>
                Describe your concern
              </p>
              <textarea
                name="feedback"
                className="w-full max-h-[120px] resize-none h-[120px] p-2 text-[14px] focus:outline-none focus:border-transparent"
                placeholder="Describe your feedback or feature request in detail"
                style={{ border: "1px solid #D9DDE5", borderRadius: 5 }}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              ></textarea>
              <div className="mt-4 flex justify-end">
                <Button
                  variant="primary"
                  type="button"
                  style={{ background: "#fff", color: "black", border: "1px solid #D9DDE5" }}
                  externalClassName="mr-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={handleFeedbackSubmit} disabled={!feedback}>
                  Submit
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackPopover;
