import React, { useEffect, useRef, useState } from "react";
import MapOnTracking from "./MapOnTracking";
import { calculateAndDisplayRoute, getDeliveryInfo } from "./MapOnTracking/helpers";
import { useParams } from "react-router-dom";
import useSocket from "../../customHooks/useSocket";
import DetailsOnTracking from "./DetailsOnTracking";

import powered from "../../assets/png/powered.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { Helmet } from "react-helmet";
import favIcon from "../../assets/png/burqFavIcon.png";

import { useFlag } from "@unleash/proxy-client-react";
import classNames from "classnames";
import styles from "./styles.module.css";
import RatingModal from "./RatingModal";
import PoweredBy from "../../assets/svgs/branding/PoweredBy";
import AvatarB from "../../assets/svgs/branding/AvatarB";
//  `function initFreshChat() {
//     window.fcWidget.init({
//       token: "162c73af-471e-4350-9871-19c558f18dc1",
//       host: "https://burq-help.freshchat.com",
//     });
//   }
//   function initialize(i, t) {
//     var e;
//     i.getElementById(t)
//       ? initFreshChat()
//       : (((e = i.createElement("script")).id = t),
//         (e.async = !0),
//         (e.src = "https://burq-help.freshchat.com/js/widget.js"),
//         (e.onload = initFreshChat),
//         i.head.appendChild(e));
//   }
//   function initiateCall() {
//     initialize(document, "Freshchat-js-sdk");
//   }
//   window.addEventListener
//     ? window.addEventListener("load", initiateCall, !1)
//     : window.attachEvent("load", initiateCall, !1)`;

const TrackingPage = () => {
  let { id } = useParams();
  let { isdesktop } = useDesktopOrLaptop();
  const [deliveryInfo, setDeliveryInfo] = useState();
  const [pickupCoordinates, setPickupCoordinates] = useState();
  const [dropoffCoordsArr, setDropoffCoordsArr] = useState([]);
  const [route, setRoute] = useState();

  const splitedPath = window.location.pathname.split("/");
  const isOrderTracking = splitedPath[1] === "orders";

  const { message } = useSocket({
    channelName: isOrderTracking ? `order@${id}` : `delivery@${id}`,
  });

  const sheetRef = useRef();
  const isFeedbackEnabled = useFlag("feedback");
  const trackingRedesign = useFlag("trackingRedesign");

  const delInfoHandler = async () => {
    return getDeliveryInfo(id, setDeliveryInfo, setPickupCoordinates, dropoffCoordsArr, setDropoffCoordsArr, isOrderTracking);
  };

  const firstLoadHandler = async () => {
    if (!deliveryInfo) {
      await delInfoHandler();
    } else if (pickupCoordinates) {
      if (
        deliveryInfo?.courier_location_lat &&
        deliveryInfo?.courier_location_lng &&
        (deliveryInfo?.status === "enroute_dropoff" ||
          deliveryInfo?.status === "arrived_at_dropoff" ||
          deliveryInfo?.status === "pickup_complete")
      ) {
        await calculateAndDisplayRoute(
          deliveryInfo.courier_location_lat,
          deliveryInfo.courier_location_lng,
          deliveryInfo,
          setRoute
        );
      } else if (!route || deliveryInfo.status === "delivered") {
        calculateAndDisplayRoute(pickupCoordinates?.lat, pickupCoordinates?.lng, deliveryInfo, setRoute);
      }
    }
  };

  // if (
  // response?.data?.branding_setting?.merchant_chat_code_snippet &&
  // !response?.data?.branding_setting?.burq_chat_enabled_on_tracking_page
  // ) {
  //   const scriptElement = document.createElement("script");
  //   scriptElement.innerHTML = Buffer.from(
  //     response?.data?.branding_setting?.merchant_chat_code_snippet,
  //     "base64"
  //   ).toString("utf-8");
  //   document.head.appendChild(scriptElement);
  // }

  useEffect(() => {
    if (message?.id === id || message?.order_id === id) {
      setDeliveryInfo({ ...deliveryInfo, ...message });
    }
  }, [message]);

  useEffect(() => {
    if (isOrderTracking && trackingRedesign) {
      return (window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/orders/track/${id}`);
    }
    firstLoadHandler();
  }, [deliveryInfo, pickupCoordinates]);

  // useEffect(() => {
  //   let meta = document.querySelector("meta[name~='viewport']");
  //   if (!meta) {
  //     meta = document.createElement("meta");
  //     meta.rel = "viewport";
  //     document.getElementsByTagName("head")[0].appendChild(meta);
  //   }
  //   meta.content = "width=device-width, initial-scale=1";

  //   return () => {
  //     meta.content = "width=1000px, user-scalable=1";
  //   };
  // }, []);

  // const imageUrl = AvatarB;
  const imageUrl = deliveryInfo?.branding_setting?.branding_image_url;
  const dynamicUrl = `${process.env.REACT_APP_TRACKING_URL}/track/${id}`;

  return (
    <div className="flex w-screen h-screen items-start relative">
      {isFeedbackEnabled && <RatingModal delInfoHandler={delInfoHandler} itemInfo={deliveryInfo} delId={deliveryInfo?.id} />}
      <Helmet>
        {/* Standard Meta Tags */}
        {/* <title>{title}</title> */}
        {/* <meta name="description" content={description} /> */}

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content={"Burq tracking"} />
        <meta property="og:description" content={"Follow your delivery in real-time"} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={dynamicUrl} />
        <meta property="og:type" content="website" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="Burq tracking" />
        {/* <meta name="twitter:title" content={title} /> */}
        {/* <meta name="twitter:description" content={description} /> */}
        <meta name="twitter:image" content={imageUrl} />
        <script>
          {deliveryInfo?.branding_setting?.merchant_chat_code_snippet &&
            !deliveryInfo?.branding_setting?.burq_chat_enabled_on_tracking_page &&
            `${Buffer.from(deliveryInfo?.branding_setting?.merchant_chat_code_snippet, "base64").toString("utf-8")}`}
        </script>
      </Helmet>

      {/* <div
        onClick={() => {
          let meta = document.querySelector("meta[name~='viewport']");
          if (!meta) {
            meta = document.createElement("meta");
            meta.rel = "viewport";
            document.getElementsByTagName("head")[0].appendChild(meta);
          }
          meta.content = "width=device-width, initial-scale=1";
        }}
      >
        dasdasd asdas da
      </div> */}

      {isdesktop && (
        <div style={{ width: "40%", height: "100%", maxWidth: "600px" }} className="">
          <DetailsOnTracking
            logo={
              deliveryInfo?.branding_setting?.icon_image_url
                ? deliveryInfo?.branding_setting?.icon_image_url + "?" + deliveryInfo?.id
                : undefined
              // : favIcon
            }
            branding={
              deliveryInfo?.branding_setting?.branding_image_url
                ? deliveryInfo?.branding_setting?.branding_image_url + "?" + deliveryInfo?.id
                : null
            }
            deliveryInfo={deliveryInfo}
            brandingColor={deliveryInfo?.branding_setting?.brand_color}
          />
        </div>
      )}

      {isdesktop ? (
        <div className="w-auto h-full flex-grow">
          <MapOnTracking
            containerElement={<div style={{ height: "100%", width: "100%" }} />}
            dropoffCoordsArr={dropoffCoordsArr}
            pickupCoordinates={pickupCoordinates}
            deliveryInfo={deliveryInfo}
            routesForMap={route}
            color={deliveryInfo?.branding_setting?.brand_color}
          />
        </div>
      ) : (
        <div style={{ width: "100%", height: "100%" }} className=" flex justify-center items-center ">
          <MapOnTracking
            containerElement={<div style={{ height: "100%", width: "100%" }} />}
            dropoffCoordsArr={dropoffCoordsArr}
            pickupCoordinates={pickupCoordinates}
            deliveryInfo={deliveryInfo}
            routesForMap={route}
            color={deliveryInfo?.branding_setting?.brand_color}
          />
          <BottomSheet
            open
            skipInitialTransition
            ref={sheetRef}
            defaultSnap={({ maxHeight }) => maxHeight / 4}
            snapPoints={({ maxHeight }) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
            blocking={false}
            expandOnContentDrag={true}
          >
            <DetailsOnTracking
              logo={
                deliveryInfo?.branding_setting?.icon_image_url
                  ? deliveryInfo?.branding_setting?.icon_image_url + "?" + deliveryInfo?.id
                  : favIcon
              }
              branding={deliveryInfo?.branding_setting?.branding_image_url + "?" + deliveryInfo?.id}
              deliveryInfo={deliveryInfo}
              brandingColor={deliveryInfo?.branding_setting?.brand_color}
            />
          </BottomSheet>
        </div>
      )}
      <div className="absolute bottom-0 right-28">
        <PoweredBy width={160} height={80} />
        {/* <img src={powered} className="w-40 h-20 object-cover" alt="" /> */}
      </div>
    </div>
  );
};

export default TrackingPage;
