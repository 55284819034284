import * as Yup from "yup";

export const initialValues = {
  name: "",
  country: "US",
  address: "",
  website: "",
  standardized_industry: "food",
  tax_or_ein: "",
  phone_number: "",
  // entity_type: "individual",
  business_description: "",
  // isOtherChosen: false,
  // user_inputted_industry: "",
};

export const formValidationSchema = Yup.object().shape({
  standardized_industry: Yup.string().required(),
  name: Yup.string().required("Business name is required"),
  address: Yup.string().required("Address is required "),
  phone_number: Yup.string()
    .test("len", "Must be exactly 11 characters", (val) => {
      return val?.length === 17;
    })
    .required("Phone code is required"),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url!"
    ),
  tax_or_ein: Yup.string(),

  // country: Yup.string().required("Country is required "),
  // entity_type: Yup.string().required("Business type is required"),
  user_inputted_industry: Yup.string().when(
    "isOtherChosen",
    (isOtherChosen) => {
      if (isOtherChosen) {
        return Yup.string().required("Business industry is required");
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  // business_description: Yup.string().required(
  //   "Product description is required"
  // ),
});
