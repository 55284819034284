import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Eye, EyeOff } from "lucide-react";
import { userDataStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../runTimeConst";

const passwordSchema = z
  .string()
  .min(10, "Password must be at least 10 characters long")
  .regex(/[0-9]/, "Password must contain at least one number")
  .regex(/[a-z]/, "Password must contain at least one lowercase letter")
  .regex(/[A-Z]/, "Password must contain at least one uppercase letter");

const formSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords must match",
    path: ["confirmPassword"],
  });

const ResetPasswordForm = () => {
  const token = new URLSearchParams(window.location.search).get("token");
  let history = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const [showPrimaryPassword, setShowPrimaryPassword] = useState(false);
  const [showSecondaryPassword, setShowSecondaryPassword] = useState(false);
  const { isSubmittedNewPassword, resetPasswordWorker } = userDataStore();

  const togglePrimaryPasswordVisibility = () => setShowPrimaryPassword((prev) => !prev);
  const toggleSecondaryPasswordVisibility = () => setShowSecondaryPassword((prev) => !prev);

  const onSubmit = (data) => {
    console.log("Password reset data:", data, "Token:", token);
    const payload = {
      token: token ?? "",
      new_password: data.password,
    };
    resetPasswordWorker(payload);
  };

  if (!token) {
    window.location.href = `${baseUrl}auth/logout`;
    return
  }

  return (
    <div className="h-screen w-full flex justify-center items-center">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 w-full max-w-md mx-auto">
        <div>
        <label className={`block text-sm font-medium ${errors.password ? "text-red-500" : "text-gray-700"}`}>
            New Password
          </label>
          <div className="relative">
            <input
              type={showPrimaryPassword ? "text" : "password"}
              placeholder="Enter new password"
              {...register("password")}
              className="w-full p-2 border rounded"
            />
            <button type="button" onClick={togglePrimaryPasswordVisibility} className="absolute right-2 top-2">
              {showPrimaryPassword ? <EyeOff size={18} /> : <Eye size={18} />}
            </button>
          </div>
          {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password.message}</p>}
        </div>

        <div>
        <label className={`block text-sm font-medium ${errors.confirmPassword ? "text-red-500" : "text-gray-700"}`}>
            Confirm Password
          </label>
          <div className="relative">
            <input
              type={showSecondaryPassword ? "text" : "password"}
              placeholder="Confirm new password"
              {...register("confirmPassword")}
              className="w-full p-2 border rounded"
            />
            <button type="button" onClick={toggleSecondaryPasswordVisibility} className="absolute right-2 top-2">
              {showSecondaryPassword ? <EyeOff size={18} /> : <Eye size={18} />}
            </button>
          </div>
          {errors.confirmPassword && <p className="text-red-500 text-xs mt-1">{errors.confirmPassword.message}</p>}
        </div>

        <button type="submit" className="w-full bg-blue-500 text-white py-2 rounded" disabled={isSubmittedNewPassword}>
          Reset Password
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
