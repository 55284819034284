import { createStore } from "aniuta";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import hexColorThemes from "../constants/hexColorThemes";
import { setCookie } from "../utils";

const useTheme = createStore({
  name: "useTheme",
  Store: () => {
    const currentTheme = Cookies.get("theme") === "false" ? false : true;
    const [themeChanger, setThemeChanger] = useState(currentTheme ? true : false);
    const [theme, setTheme] = useState(currentTheme ? "light" : "dark");
    const [dropoffCoordinatesArr, setDropoffCoordinatesArr] = useState([]);

    useEffect(() => {
      let colors = null;
      if (theme === "light") {
        document.documentElement.classList.remove("dark");
        colors = hexColorThemes.light;
      } else if (theme === "dark") {
        document.documentElement.classList.add("dark");
        colors = hexColorThemes.dark;
      }
      for (const [key, value] of Object.entries(colors)) {
        document.documentElement.style.setProperty(key, value);
      }
    }, [theme]);

    const changeTheme = () => {
      setCookie("theme", !themeChanger);
      setThemeChanger(!themeChanger);
      setTheme(themeChanger ? "dark" : "light");
    };

    const Colors = themeChanger
      ? {
          alertRed: "#ff9966",
          fontDark: "#171725",
          bluePrimary: "#0062FF",
          chartSecondLine: "#9DA6B7",
          chartOrangeLine: "#EB4A4A",
          synchronizedAreaChartGreen: "#24A873",
          synchronizedAreaChartOrange: "#FF8528",
          synchronizedAreaChartPurple: "#9360FF",
          synchronizedAreaChartWarning: "#FFDABF",
          fontGreyLight: "#92929D",
          bgGrey: "#F2F2F2",
          white: "#ffffff",
          borderPrimary: "#F2F2F7",
          blueLight: "#62B6FF",
          redDark: "#FF3F3F",
          yellowDark: "#FF974A",
          greenDark: "#3DD598",
          carBg: "#F2F4FF",
          vanBg: "#F1FAFF",
          truckBg: "#EAF4FF",
          buttonBg: "#FAFAFB",
          purple: "#7D8AD5",
          accentBlue: "#96B5E8",
          sidePanelBgColor: "#F7F9FB",
          bottomLines: "#ffffff",
          bgDarkSecondary: "#ffffff",
          borderSecondary: "#E2E2EA",
          bgListHeader: "#FAFAFB",
          asapContainer: "#EFFBF5",
          createFirstColors: "#D2E9FF",
          createFirstC: "#f0f8ff",
          detailsBg: "#F7FAFC",
          fontBlack: "#000000",
          whiteBlue: "#EFF4F8",
          whitestBlue: "#F7FAFC",
          driverBoxesBg: "#ffffff",
          bgDots: "#ECEEF0",
          bgWorkflow: "#FBFBFB",
          bgSettings: "#F5F5F5",
          percentUp: "#24A873",
          percentDown: "#EB4A4A",
          chartMonth: "#83879B",
          synchronizedAreaChartLable: "#9DA6B7",
          tableRow: "#F9FAFB",
          chartBlueLighter: "#CBE5FC",
          chartBlueLight: "#59B1F9",
          chartBlue: "#3F8EF1",
          chartBlueDark: "#2E6CC4",
          chartBlueDarker: "#1B448D",
          grayLabel: "#808080",
          tableBorderGray: "#e5e7eb",
          grayButton: "#ECEEF2",
        }
      : {
          alertRed: "#ff9966",
          fontDark: "#F9FAFB",
          bluePrimary: "#0062FF",
          chartSecondLine: "#9DA6B7",
          fontGreyLight: "#BFBFBF",
          bgGrey: "#3C4257",
          white: "#191E34",
          // white: "#ffffff",
          borderPrimary: "#3C4257",
          // borderPrimary: "#F2F2F7",
          blueLight: "#62B6FF",
          redDark: "#FF3F3F",
          yellowDark: "#FF974A",
          greenDark: "#3DD598",
          carBg: "#F2F4FF",
          vanBg: "#F1FAFF",
          truckBg: "#EAF4FF",
          buttonBg: "#FAFAFB",
          purple: "#7D8AD5",
          accentBlue: "#96B5E8",
          // sidePanelBgColor: "#F7F9FB",
          sidePanelBgColor: "#030000",
          bgDarkSecondary: "#292E44",
          bgListHeader: "#292D44",
          asapContainer: "#21252F",
          createFirstColors: "#155089",
          lightestBlue: "#F7FAFC",
          createFirstC: "#5db3ff",
          detailsBg: "#292E44",
          fontBlack: "#FFFFFF",
          whiteBlue: "#20253A",
          whitestBlue: "#292E44",
          driverBoxesBg: "#292E45",
          bgDots: "#191E34",
          bgWorkflow: "#2B2F44",
          bgSettings: "#292E44",
          percentUp: "#24A873",
          percentDown: "#EB4A4A",
          chartMonth: "#83879B",
          chartOrangeLine: "#EB4A4A",
          synchronizedAreaChartGreen: "#24A873",
          synchronizedAreaChartOrange: "#FF8528",
          synchronizedAreaChartLable: "#ffff",
          synchronizedAreaChartPurple: "#9360FF",
          synchronizedAreaChartWarning: "#FFDABF",
          charGridColor: "#EDF2F7",
          tableRow: "#3C4257",
          chartBlueLighter: "#CBE5FC",
          chartBlueLight: "#59B1F9",
          chartBlue: "#3F8EF1",
          chartBlueDark: "#2E6CC4",
          chartBlueDarker: "#1B448D",
        };

    return {
      themeChanger,
      setThemeChanger,
      Colors,
      dropoffCoordinatesArr,
      setDropoffCoordinatesArr,
      changeTheme,
    };
  },
});

export default useTheme;
