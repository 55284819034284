import { PiInfo } from "react-icons/pi";
import Button from "../../atoms/Button";
import { useNavigate } from "react-router-dom";
import styles from "./suspendedAccountAlert.module.css";
import { useMerchant, useMyStore } from "../../../../store";
import useViewportMeta from "../../../../customHooks/useViewportMeta";

const SuspendedAccountAlert = ({ hideCtaBtn = false }) => {
  useViewportMeta();
  let history = useNavigate();
  const { isSandbox } = useMyStore();
  const { isAccountSuspended } = useMerchant();

  if (!isAccountSuspended || isSandbox) {
    return null;
  }

  return (
    <div
      className="relative flex md:flex-row flex-col gap-3 rounded-lg border items-center"
      style={{
        marginTop: "12px",
        borderColor: "#FFE099",
        width: "100%",
        backgroundColor: "#FFFDF8",
        padding: "17px",
      }}
    >
      <div className="flex flex-col flex-1 gap-1  items-start shrink-0" style={{ flex: "auto" }}>
        <div className="flex gap-3">
          <PiInfo size={16} color="#EBAD1E" />
          <span
            className="self-stretch font-poppins text-base font-medium leading-[16px] tracking-[-0.4px]"
            style={{
              color: "#1C2029",
            }}
          >
            Your account has unpaid balance
          </span>
        </div>
        <span
          className=" 2xl:pl-7 sm:pl-6 pl-4 font-poppins text-md font-normal leading-6 self-stretch"
          style={{
            color: "#343C4C",
          }}
        >
          Once you update your default payment method, we’ll retry your pending payments and restore your suspended account.
        </span>
      </div>
      {!hideCtaBtn && (
        <Button
          text="Update payment method"
          externalClassName={styles.button}
          onClick={() => history("/v1/settings/payments?setPaymentMethod=true")}
        />
      )}
    </div>
  );
};

export default SuspendedAccountAlert;
