import React from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import { useClickStore, useMerchant, useMyStore, useTheme } from "../../store";
import { CirclePlus } from "lucide-react";
import ToolTip from "../Tooltip/Tooltip";

const CreateOrderButton = () => {
  const { Colors } = useTheme();
  const { isSandbox } = useMyStore();
  const { isdesktop } = useDesktopOrLaptop();
  const { isBurgerMenuVisible } = useClickStore();
  const { isMissingPaymentMethod, isAccountSuspended } = useMerchant();

  const disableCreateOrder = isAccountSuspended && !isSandbox;

  return (
    <>
      <button
        type="button"
        data-tip
        data-for="create-order-btn"
        id="goToCreateOrderPage"
        disabled={disableCreateOrder}
        onClick={() => {
          window.location.href = `${process.env.REACT_APP_NEW_REACT_DOMAIN}/v1/orders?create_order=true`;
        }}
        className={isMissingPaymentMethod || (disableCreateOrder && "dark:bg-slate-800")}
        style={{
          width: isdesktop && "100%",
          backgroundColor: isMissingPaymentMethod || disableCreateOrder ? Colors.grayButton : Colors.bluePrimary,
          fontSize: isdesktop ? "16px" : "28px",
          fontWeight: "500",
          color: "white",
          height: isdesktop ? "44px" : "80px",
          textAlign: "center",
          borderRadius: "8px",
          padding: !isdesktop && "12px 22px",
        }}
      >
        {isBurgerMenuVisible ? (
          <div
            className={`flex items-center justify-center w-full gap-2 ${
              isMissingPaymentMethod || disableCreateOrder ? "text-gray-1200 dark:text-gray-700" : "text-white"
            }`}
          >
            <CirclePlus size={22} strokeWidth={1.5} />
            Create Order
          </div>
        ) : (
          <div
            className={`flex items-center justify-center w-full ${
              isMissingPaymentMethod || disableCreateOrder ? "text-gray-1200 dark:text-gray-700" : "text-white"
            }`}
          >
            <CirclePlus size={22} strokeWidth={1.5} className={!isdesktop && "mr-1.5"} />
            {!isdesktop && "Create Order"}
          </div>
        )}
      </button>
      <ToolTip
        id="create-order-btn"
        clickable
        disable={!disableCreateOrder}
        backgroundColor="#fff"
        effect="solid"
        className="!rounded-[6px] !border !border-[#d9d9d9] max-w-80 !p-4"
        text={
          <span className="font-poppins text-sm text-gray-1200 leading-6">
            Orders cannot be created since your account is temporarily suspended. Please update your payment method to restore
            your account.
          </span>
        }
      />
    </>
  );
};

export default CreateOrderButton;
