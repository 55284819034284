import React from "react";
import { useProofDownloader, useTheme } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

function ImagesContainer({ images, id, title }) {
  const { Colors } = useTheme();

  const { isdesktop, smfontsize } = useDesktopOrLaptop();

  const { onDownloadImages } = useProofDownloader();

  if (typeof images === "string") {
    images = JSON.parse(images);
  }

  return (
    <div style={{ width: "100%" }} className="flex flex-col">
      <div
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: smfontsize,
          marginTop: "24px",
          color: Colors.fontDark,
          width: "100%",
          backgroundColor: Colors.whiteBlue,
          // height: "31px",
          padding: "8px 12px",
          alignItems: "center",
          display: "flex",
          borderRadius: "5px 5px 0px 0px",
          justifyContent: "space-between",
        }}
      >
        <p style={{ color: Colors.fontDark, marginBottom: "0px" }}>{title}</p>
        <button
          // disabled
          style={{ color: Colors.bluePrimary }}
          onClick={() => onDownloadImages(id)}
        >
          Download
        </button>
      </div>
      <div
        className="noScroll"
        style={{
          paddingTop: "15px",
          backgroundColor: Colors.whitestBlue,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          borderRadius: "0px 0px 10px 10px",
          padding: "14px 14px 15px",
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          overflowX: "auto",
        }}
      >
        {images?.map((url, index) => {
          return (
            <a href={url} target="_blank" rel="noreferrer">
              <img
                key={index}
                style={{
                  height: isdesktop ? "110px" : "90px",
                  width: isdesktop ? "110px" : "90px",
                  objectFit: "contain",
                  margin: "0px 10px 0px 0px",
                }}
                alt=""
                src={url}
              />
            </a>
          );
        })}
        {/* <a
          href={
            "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"
          }
          target="_blank"
        >
          <img
            style={{
              height: isdesktop ? "100px" : "200px",
              width: isdesktop ? "100px" : "200px",
              objectFit: "cover",
              margin: "0px 10px 0px 0px",
            }}
            alt=""
            src={
              "https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg"
            }
          />
        </a> */}
      </div>
    </div>
  );
}

export default ImagesContainer;
